/* ===============
   Variables
   =============== */

/*** Breakpoints ***/
$breakpoints: (
  small: 576px,
  medium: 768px,
  large: 992px,
  x-large: 1200px,
  xx-large: 1400px,
);

//Mix in to call in classes to apply responsiveness properties
@mixin breakpoint($point) {
  @media (max-width: map-get($breakpoints, $point)) {
    @content;
  }
}

// Example:
//  {
//     background-color: blue;

//     @include breakpoint(small) {
//       background-color: red;
//     }
//   }

//Mixin to apply stylings while dark mode is active
@mixin darkTheme() {
  @container dark-theme (min-width: 0px) {
    @content;
  }
}

// Example:
// &__blue {
//   color: color(blue, base);
//   border: 1px solid color(blue, 250);
//   background-color: color(blue, 100);
//   @include darkTheme {
//     color: color(blue, 375);
//     background-color: color(blue, 500);
//   }
// }

/*** Z-Index ***/
$z-low: 10;
$z-mid: 100;
$z-high: 1000;

/*** Spacer ***/
$spacerBase: 4px;

@function spacer($value) {
  @return $value * $spacerBase;
}
