.back-arrow {
  display: flex;
  color: #1C7BC0;
  text-decoration: none;
  cursor: pointer;
  margin-left: -10px;
}

.user-detail-wrapper {
  .detail-header {
    display: flex;
    align-items: center;
  }
  .form-elements {
    width: 49%;
    .MuiFormControl-root {
      margin-bottom: 15px;
    }
    .name-fields {
      display: flex;
      justify-content: space-between;
      .MuiFormControl-root {
        width: 45%;
      }
    }
  }
  .action-buttons {
    button {
      margin-right: 10px;
    }
  }
}
.system-user-btn-row {
  margin-top: 20px;
  button {
    &:first-of-type {
      margin-right: 10px;
    }
  }
}

.edit-user-submit {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  width: 14%;
}
.details-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 15px;
  .permissions-wrapper {
    h3 {
      margin: 0px;
    }
    width: 35%;
    display: flex;
    flex-direction: column;
    .MuiFormControlLabel-root {
      justify-content: space-between;
      margin-left: 0px;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 800px) {
  .user-detail-wrapper {
    .form-elements {
      width: 100%;
    }
    .permissions-wrapper {
      width: 100%;
    }
  }
  .system-user-btn-row {
    margin-top: 0px;
    padding-right: 15px;
    button {
      width: 100%;
      &:first-of-type {
        margin: 10px 0px;
      }
    }
  }
}
