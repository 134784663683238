/* ===============
   Typography
   =============== */

/*** Headings ***/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

h1,
.h1 {
  font-weight: 700;
  font-size: 28px;
}
h2,
.h2 {
  font-weight: 700;
  font-size: 25px;
}

h3,
.h3 {
  font-weight: 500;
  font-size: 20px;
}
h4,
.h4 {
  font-weight: 400;
  font-size: 16px;
}
h5,
.h5 {
  font-weight: 700;
  font-size: 16px;
}
h6,
.h6 {
  font-weight: 400;
  font-size: 14px;
}

/*** Paragraph ***/
p,
.paragraph {
  margin: 0px;
  font-size: 14px;
}

.caption {
  font-size: 12px;
  font-weight: 400;
}

.sub-2 {
  font-size: 14px;
  font-weight: 500;
}
// .button {
//   font-size: 16px;
//   font-weight: 500;
// }
