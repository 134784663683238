.filter-status-menu {
  .MuiMenuItem-root {
    padding-top: 0;
    padding-bottom: 0;

    &:hover {
      background-color: transparent;
    }
  }
}

.reset-filter-button {
  float: right;
}

.create-search-div {
  justify-content: space-between;

  .create-invoice-btn {
    height: 40px;
    margin-bottom: 1em;
  }
}

.action-menu .MuiMenuItem-root svg {
  margin-right: 10px;
}

.invoices-wrapper {
  .MuiTableContainer-root {
    overflow-x: initial;
  }

  th {
    top: 40px;
  }

  .download-button {
    position: sticky;
    top: 0;
    background-color: white;
    flex-grow: 1;
    text-align: right;
    padding: 10px 10px 10px 0px;
    z-index: 1000;
  }
}

.dark-theme {
  .invoices-wrapper {
    .download-button {
      background-color: #121212;
    }
  }
}
