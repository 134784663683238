@use "sass:map";
@import "../../assets/colors.module.scss";
$side-nav-light: (
  "background": #fafafa,
  "secondary": #ebebeb,
  "accent": #d9d9d9,
);
$side-nav-dark: (
  "background": #323741,
  "secondary": #2a2d35,
  "accent": #858585,
);
//will move to global file if used in more designs
$side-nav-light-blue: #e8f6fc;
$side-nav-dark-blue: #5fc3ed;
.side-nav-container {
  .MuiPaper-root {
    background: map-get($map: $side-nav-light, $key: "background");
    padding-top: 20px;
    justify-content: space-between;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .mobile-expand-nav {
    display: none;
    margin-right: 10px;
  }
  .expand-collapse-btn:focus-visible {
    background: rgba(0, 0, 0, 0.12);
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap: wrap;
    margin-bottom: 12px;
    .MuiButtonBase-root {
      svg {
        font-size: 40px;
        font-weight: 100;
        color: black;
      }
    }
  }

  ul {
    .MuiListItemButton-root {
      margin: 4px 16px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 0px;
      .button-text {
        padding: 4px 8px;
        border-radius: 4px;
      }
      .button-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        > svg {
          font-size: 20px;
        }
      }
      &.selected {
        color: #0c84bd;
        padding-right: 8px;
        path {
          fill: #0c84bd;
        }
        .button-text {
          background: $side-nav-light-blue;
        }

        .button-wrapper {
          background: $side-nav-light-blue;
          padding: 4px 8px;
          .MuiListItemText-root {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
      }
      &:hover {
        background: none;
        color: #0c84bd;
        path {
          fill: #0c84bd;
        }
      }
    }
    .MuiCollapse-wrapper {
      .MuiListItem-root {
        width: 55%;
        margin-left: 6em;
        margin-top: 4px;
        margin-bottom: 4px;
        padding-top: 0;
        padding-bottom: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &:hover:not(.active) {
          border-radius: 4px;
        }
      }
    }
  }
  .logo-btn {
    justify-content: start;
    min-width: fit-content;
    .mini-logo {
      margin-left: 10px;
      width: 55px;
    }
    .logo {
      width: 100px;
      margin-left: 10px;
    }
  }
  .header-row {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    button {
      color: #00000099;
    }
  }
  .gateway-wrapper {
    padding: 12px 16px;
    padding-top: 0px;
  }
  .user-badge {
    height: 40px;
    width: 40px;
    background-color: $side-nav-light-blue;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 16px;
    margin-right: 15px;
  }
  .user-details-container {
    width: 100%;
    padding: 0px 16px;
  }
  .user-details-block {
    display: flex;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid map-get($map: $side-nav-light, $key: "accent");
    p {
      margin: 0px;
    }
    .impersonator-block {
      p {
        margin-top: 8px;
      }
    }
  }
  //Mini menu BEN
  &.collapsed {
    .logo-btn__mini {
      img {
        width: 48px;
      }
    }
    .MuiPaper-root {
      overflow-y: unset;
    }
    .logo-btn {
      img {
        width: 160px;
      }
    }
    ul {
      .MuiListItemButton-root {
        padding-left: 16px;
      }
    }
    .button-wrapper {
      justify-content: center;
      flex-direction: column;
      .MuiButtonBase-root {
        width: 100%;
        &.expand-collapse-btn {
          margin-top: 24px;
          padding: 8px 0px;
          border-radius: 0px;
          &:focus-visible {
            background: rgba(0, 0, 0, 0.12);
          }
          &:hover {
            svg path {
              fill: #0c84bd;
            }
          }
        }
      }
    }
    .gateway-wrapper,
    .bottom-section {
      display: none;
    }
    .MuiListItemText-root {
      display: none;
    }
    .MuiListItemButton-root {
      margin: 0px;
      display: flex;
      justify-content: center;
      &.selected {
        padding-right: 16px;
      }
      .button-wrapper {
        display: none;
      }
    }
    .MuiListItemIcon-root {
      margin-right: 0px;
    }
    .collapsed-badge {
      display: flex;
      justify-content: center;
      cursor: pointer;
      .user-badge {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }

  &.dark {
    .MuiPaper-root {
      background: map-get($map: $side-nav-dark, $key: "background");
    }

    .top-section {
      svg {
        path {
          fill: white;
        }
      }
    }
    .gateway-block {
      border-color: map-get($map: $side-nav-dark, $key: "accent");
    }
    .mini-logo {
      width: 55px;
    }
    .logo {
      margin-left: 16px;
      width: 100px;
    }
    p {
      color: white;
    }
    .header-row {
      button {
        color: white;
      }
    }
    .user-details-container {
      background: inherit;
    }
    .user-badge {
      background: map-get($map: $side-nav-dark, $key: "secondary");
      color: white;
    }
    .autocomplete-sidenav {
      background: map-get($map: $side-nav-dark, $key: "secondary");
      .MuiButtonBase-root {
        color: map-get($map: $side-nav-dark, $key: "accent");
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: map-get($map: $side-nav-dark, $key: "accent");
      }
    }
    .logout-btn {
      border-color: map-get($map: $side-nav-dark, $key: "accent");
      button {
        color: white;
        &:hover,
        :focus {
          background: map-get($map: $side-nav-light, $key: "secondary");
          color: black;
        }
      }
    }
    .theme-toggle-container {
      background: map-get($map: $side-nav-dark, $key: "secondary");
    }
    .theme-toggle {
      border: 1px solid map-get($map: $side-nav-dark, $key: "accent");
      background: map-get($map: $side-nav-dark, $key: "secondary");
      .MuiToggleButtonGroup-grouped {
        color: white;

        &:hover {
          background: $grey825;
        }

        &.Mui-selected {
          background: $blue550;

          &:hover {
            background: $blue575;
            background-image: linear-gradient(rgb(0 0 0/20%) 0 0);
          }
        }
      }
    }
    .copyright-text {
      a {
        color: white;
      }
    }

    .user-details-block {
      border-top: 1px solid map-get($map: $side-nav-dark, $key: "accent");
    }
    ul {
      .MuiListItemButton-root {
        svg path {
          fill: white;
        }
        .button-text {
          padding: 4px 8px;
        }
        &.selected {
          svg path {
            fill: $blue300;
          }
          .button-text {
            color: $blue300;
            background: map-get($map: $side-nav-dark, $key: "secondary");
          }

          .button-wrapper {
            color: $blue300;
            background: map-get($map: $side-nav-dark, $key: "secondary");
          }
        }
        &:hover {
          color: $blue300;
          path {
            fill: $blue300;
          }
        }
      }
      .MuiCollapse-wrapper {
        .MuiListItem-root {
          &.active {
            background: map-get($map: $side-nav-dark, $key: "secondary");
            border-color: $blue300;
          }
          &:hover {
            color: white;
          }
        }
      }
    }
    &.collapsed {
      .logo-btn__mini img {
        margin-left: 0px;
      }
      .button-wrapper .expand-collapse-btn {
        path {
          fill: white;
        }
      }
    }
    .button-wrapper {
      .expand-collapse-btn {
        &:last-of-type {
          &:focus-visible {
            background: rgba(255, 255, 255, 0.12);
          }
        }
      }
    }
  }
}

.gateway-block {
  border-bottom: 1px solid map-get($map: $side-nav-light, $key: "accent");
  border-top: 1px solid map-get($map: $side-nav-light, $key: "accent");
  padding: 16px 8px;
  p {
    margin: 0px;
    font-weight: 600;
  }
}
.autocomplete-sidenav {
  background: white;
  width: initial;
  .MuiAutocomplete-inputRoot {
    padding: 0px;
    padding-left: 6px;
  }
  .MuiButtonBase-root {
    color: map-get($map: $side-nav-light, $key: "accent");
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: map-get($map: $side-nav-light, $key: "accent");
  }
}
.logout-btn {
  border-bottom: 1px solid map-get($map: $side-nav-light, $key: "accent");
  padding: 8px;
  button {
    background: inherit;
    color: black;
    padding-left: 5px;
    width: 100%;
    justify-content: flex-start;
    &:hover,
    :focus {
      background: darken(map-get($map: $side-nav-light, $key: "secondary"), 5%);
    }
  }
}

.theme-toggle-container {
  width: 100%;
  height: 40px;
  background: map-get($map: $side-nav-light, $key: "secondary");
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin: 16px 16px;
}
.theme-toggle {
  background: map-get($map: $side-nav-light, $key: "secondary");
  width: 100%;
  padding: 4px;
  .MuiToggleButtonGroup-grouped {
    width: 50%;
    font-size: 14px;
    text-transform: capitalize;
    color: black;
    svg {
      margin-right: 5px;
      font-size: 1rem;
    }
  }
  .MuiToggleButton-root {
    border: none;
    padding: 6px;
    &.MuiButtonBase-root {
      border-radius: 4px;
    }

    &:hover {
      background: $grey300;
      color: black;
    }

    &.Mui-selected {
      background: $blue300;
      color: white;

      &:hover {
        background: $blue400;
      }
    }
  }
}

.sub-item-menu {
  z-index: 12000;
  padding-left: 30px;
  .sub-menu-wrapper {
    background: map-get($map: $side-nav-light, $key: "background");
    padding: 10px;
    border-radius: 4px;
    > .MuiGrid-root {
      justify-content: initial;
      padding: 10px;
      &:last-of-type {
        padding-top: 0px;
      }
    }
  }

  .user-badge {
    display: none;
  }
  .user-details-col {
    p {
      font-size: 18px;
    }
  }

  .theme-toggle-container {
    margin: 0px;
    min-width: 220px;
    height: 36px;
  }
  .logout-btn {
    border-bottom: none;
    padding: 0px;
    font-size: 16px;
    margin: 5px 0px;
  }

  .MuiButtonBase-root {
    margin-left: 0px;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    &.active {
      padding-left: 16px;
      border-left: none;
      background: $side-nav-light-blue;
    }
  }
  .MuiButtonBase-root:not(.active) {
    .MuiListItemText-root {
      span {
        font-weight: 100;
      }
    }
  }

  &.dark {
    .sub-menu-wrapper {
      background: map-get($map: $side-nav-dark, $key: "background");
      .logout-btn {
        border-color: map-get($map: $side-nav-dark, $key: "accent");
        button {
          color: white;
          &:hover,
          :focus {
            background: map-get($map: $side-nav-light, $key: "secondary");
            color: black;
          }
        }
      }
      .theme-toggle-container {
        background: map-get($map: $side-nav-dark, $key: "secondary");
      }
      .theme-toggle {
        border: 1px solid map-get($map: $side-nav-dark, $key: "accent");
        background: map-get($map: $side-nav-dark, $key: "secondary");
        .MuiToggleButtonGroup-root {
          color: white;
          
          &.Mui-selected {
            background: $blue550;

            &:hover {
              background: $blue575;
              background-image: linear-gradient(rgb(0 0 0/20%) 0 0);
            }
          }
        }
      }

      .MuiButtonBase-root {
        &:hover {
          color: white;
        }
        &.active {
          background: #2a2d35;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .side-nav-container {
    .mobile-expand-nav {
      display: block;
    }
    .MuiIconButton-root:not(.mobile-expand-nav) {
      display: none;
    }
  }
}
