.MuiToolbar-root {
  > div {
    justify-content: space-between;
  }

  .user-badge {
    height: 35px;
    width: 35px;
    background-color: #c23f38;
    border: #c23f38;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-right: 15px;
    display: flex;
  }
}

.mobile-header {
  img {
    width: 100px;
  }
}
.MuiAppBar-positionFixed {
  display: flex;
}
@media (min-width: 768px) {
  .MuiAppBar-positionFixed {
    display: none;
  }
}
