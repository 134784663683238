/* ===============
   Buttons
   =============== */

.btn {
  font-size: spacer(4);
  font-weight: 500;
  padding: 6px spacer(4);
  cursor: pointer;
  border-radius: 4px;

  &.sm {
    padding: 2px spacer(5);
  }

  &.lg {
    padding: 10px spacer(8);
  }
}

.primary--disabled {
  color: color(neutrals, 300);
  border: 1px solid color(neutrals, 300) !important;
  background-color: var(--primary-disabled-background) !important;
}

.secondary--disabled {
  color: color(neutrals, 300) !important;
  border: 1px solid color(neutrals, 300) !important;
  background-color: var(--secondary-background) !important;
}

.btn--primary {
  @extend .btn;
  color: color(neutrals, white);
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-blue);

  &:hover {
    background-color: var(--primary-hovered-background);
    border-color: var(--primary-hovered-background);
  }

  &:disabled {
    @extend .primary--disabled;
  }

  &__danger {
    @extend .btn;
    color: color(neutrals, white);
    background-color: color(error, 400);
    border: 1px solid color(error, 400);

    &:hover {
      background-color: color(error, 300);
      border-color: color(error, 300);
    }

    &:disabled {
      @extend .primary--disabled;
    }
  }
}

.btn--secondary {
  @extend .btn;
  color: var(--secondary-border);
  border: solid 1px var(--secondary-border);
  background-color: var(--secondary-background);

  &:hover {
    border: solid 1px var(--secondary-hovered-border);
    color: var(--secondary-hovered-border);
    background-color: var(--secondary-background);
  }

  &:disabled {
    @extend .secondary--disabled;
  }

  &__danger {
    @extend .btn;
    color: color(error, 400);
    border: solid 1px color(error, 400);
    background-color: inherit;

    &:hover {
      border: solid 1px color(error, 300);
      color: color(error, 300);
      background-color: var(--secondary-background);
    }

    &:disabled {
      @extend .secondary--disabled;
    }
  }
}

.btn--link {
  color: var(--primary-blue);
  background: none;
  border: none;
  text-decoration: underline;
  display: block;
  cursor: pointer;
  &:hover {
    color: color(blue, 300);
    background-color: --link-hovered-background;
  }

  &:disabled {
    color: var(--link-disabled);
  }

  &__danger {
    @extend .btn;
    color: color(error, 400);

    &:hover {
      color: color(error, 300);
      background-color: var(--link-hovered-background);
    }

    &:disabled {
      color: color(neutrals, 300);
    }
  }
}

.MuiToggleButton-root {
  padding: 4px 12px;
  font-size: 14px;

  &.Mui-selected {
    border-color: color(blue, base);
    color: color(blue, base);
    background: white;
  }
}
