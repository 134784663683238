@import "../../../assets/v2/colors";
@import "../../../assets/v2/variables";
@import "../../../assets/v2/layout";
@import "../../../assets/v2/theme";

.btn-grp {
  width: fit-content;
  float: right;
}

.create-terminal-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: fit-content;
  @extend .card-obj;
}

.app-table {
  .MuiTableCell-root {
    max-width: 150px;
    word-break: break-all;
    padding: 8px;
  }
}

.mobile-status {
  visibility: hidden;
}

.create-error-msg {
  padding: spacer(4);
  background-color: var(--failed-bck-color);
  border-radius: spacer(1);
  border: 1px solid var(--failed-border);
}

.terminal-container {
  height: 440px !important;
  overflow-y: scroll;
}

@media screen and (min-width: 1441px) {
  .terminal-container {
    height: 700px !important;
  }
}

@include breakpoint(x-large) {
  .mobile-status {
    visibility: visible;
  }

  .big-screen {
    visibility: hidden;
  }
}

.tip-preference-input {

  input[type="number"],
  input[type="text"] {
    appearance: textfield !important;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }

  .MuiInputAdornment-root {
    background-color: transparent !important; // Ensure transparent background
    position: absolute;
    right: 0.5rem; // Position adornment to avoid overlap with input text
    pointer-events: none; // Allow clicks to pass through to the input
  }
}