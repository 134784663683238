.hide-ach {
  &:nth-of-type(2) {
    display: none;
  }
}

.no-card-hide-ach {
  &:nth-of-type(1) {
    display: none;
  }
}

.hide-swipe {
  &:nth-of-type(4) {
    display: none;
  }
}

.no-card-hide-swipe {
  &:nth-of-type(3) {
    display: none;
  }
}
