@use "sass:map";
@import "../../../../assets/v2/colors.scss";
@import "../../../../assets/v2/variables.scss";

.subscription-status {
  float: right;
  max-height: 32px;
}

.invoice-status {
  margin: 0 auto;
}

.loading-icon {
  margin-left: 0.5em;
}

#delete-subscription-button {
  color: color(error, 400);
}

.subscription-details-container {
  .MuiDivider-root {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.12);
  }

  .subscription-details {
    background-color: var(--card);
    padding: 1em;
    border-radius: 5px;
    margin-left: 0;
    margin-top: 0;

    #subscription-customer-name {
      color: color(blue, base);
    }

    #subscription-customer-name-button {
      padding: 0;
      min-width: 0;
    }

    .subscription-summary-field {
      padding: 0;
      margin-bottom: 1em;
    }

    #subscription-customer-edit-button {
      padding: 0;
      margin-left: 0.5em;

      svg {
        fill: color(blue, base);
        width: 1em;
        height: 1em;
      }
    }

    .subscription-address-accordion-button {
      padding: 0;
      text-decoration: underline;
      margin-bottom: 1.5em;

      &:hover {
        background: transparent;
      }
    }

    label + .MuiInput-root {
      margin-top: 0;
    }

    .contact-autocomplete {
      display: inline-flex;
      width: 100%;
      top: -4px;
      position: relative;

      .MuiOutlinedInput-input {
        position: absolute;
        width: 100%;
      }
    }

    #end-subscription-dropdown {
      position: relative;
      padding-top: 0;
      top: -2px;
    }

    #auto-renew-dropdown {
      padding-top: 10px;
    }

    .billing-schedule,
    .payment-details {
      padding-top: 0;

      &.payment-method {
        padding-left: 2.5em;

        .customer-payment-method-details {
          opacity: 0.5;
        }

        .MuiButton-root {
          text-decoration: underline;
          padding: 0;

          &:hover {
            background: none;
          }
        }
      }
    }

    #subscription-trial-days {
      padding-top: 0.5em;
    }

    .subscription-end-details .MuiTextField-root {
      margin-top: 14px;
    }

    .processor-select-container {
      .MuiInputLabel-root {
        margin-top: 0;
      }
    }

    .adjusted-invoice-message {
      margin-left: 2em;
    }

    #unit-of-measure-select {
      padding-top: 0;
      margin-top: -2px;
    }
  }

  .payment-details-heading {
    margin-bottom: 0.5em;
  }

  #save-button {
    margin-left: 1em;
  }

  .update-button-container {
    .MuiInputBase-root {
      height: 42px;
    }

    .MuiInputLabel-root {
      position: absolute;
      transform: translate(8px, 12px) scale(1);
    }

    .MuiSelect-select {
      padding-left: 3em;
    }
  }
}

.subscription-modal {
  width: 625px;
  overflow: hidden;

  .MuiGrid-root {
    text-align: left;
  }

  .MuiButton-root {
    max-width: none;
  }

  .customer-payment-method-option {
    margin-left: 0.75em;
    width: 100%;

    .MuiFormControlLabel-label {
      width: 100%;

      .customer-payment-method-details {
        width: 100%;
      }
    }
  }

  h2 {
    align-items: center;

    svg {
      margin-right: 0.5em;
      width: 36px;
      height: 36px;
      cursor: auto;

      path {
        fill: color(blue, base);
      }
    }

    .warning-icon {
      path {
        fill: color(error, 400);
      }
    }

    .MuiIconButton-root {
      width: 0.75em;
      height: 0.75em;
      position: absolute;
      right: 1em;
      top: 1.25em;

      .close-button {
        width: 0.75em;
        height: 0.75em;
        cursor: pointer;
        margin: 0;

        path {
          fill: black;
        }
      }
    }
  }

  .cancel-dialog-refund-container {
    width: 100%;
  }
}

.dark-theme {
  .change-payment-method-modal {
    h2 svg path {
      //fill: white;
    }
  }
}
