@import "./typography";
.tag {
  padding: 6.5px spacer(2);
  font-size: spacer(3);
  font-weight: 400;
  text-align: center;
  border-radius: spacer(1);
  width: fit-content;

  &__success {
    color: var(--success-color);
    border: 1px solid var(--success-border);
    background-color: var(--success-bck-color);
  }
  &__failed {
    color: var(--failed-color);
    border: 1px solid var(--failed-border);
    background-color: var(--failed-bck-color);
  }

  &__warning {
    color: var(--warning-color);
    border: 1px solid var(--warning-border);
    background-color: var(--warning-bck-color);
  }

  &__grey {
    color: color(neutrals, 500);
    border: 1px solid color(neutrals, 300);
    background-color: color(neutrals, 100);
  }

  &__blue {
    color: color(blue, base);
    border: 1px solid color(blue, 250);
    background-color: color(blue, 100);
    @include darkTheme {
      color: color(blue, 375);
      background-color: color(blue, 500);
    }
  }

  &__purple {
    color: color(purple, 400);
    border: 1px solid color(purple, 200);
    background-color: color(purple, 100);
  }

  &__yellow {
    color: color(yellow, 400);
    border: 1px solid color(yellow, 300);
    background-color: color(yellow, 100);
  }

  &__pink {
    color: color(pink, 300);
    border: 1px solid color(pink, 200);
    background-color: color(pink, 100);
  }

  &.sm {
    padding: 4px;
  }
  &.important {
    font-weight: 600;
  }
}

@at-root .dark-theme {
  .tag {
    &__purple {
      color: color(purple, 300);
      border: 1px solid color(purple, 500);
      background-color: darken(color(purple, 500), 10%);
    }

    &__yellow {
      color: color(yellow, 300);
      border: 1px solid color(yellow, 400);
      background-color: color(yellow, 600);
    }

    &__pink {
      color: color(pink, 400);
      border: 1px solid color(pink, 500);
      background-color: color(pink, 600);
    }
  }
}

.table {
  .MuiTableHead-root {
    .MuiTableCell-root {
      @extend .sub-2;
      background-color: var(--table-header);
    }
  }
  .MuiTableBody-root {
    .MuiTableCell-root {
      @extend .h6;
      background-color: var(--table-body);
    }
  }
}
