@use "../../../assets/colors.module.scss" as colors;

.simplepay-wrapper {
  >h1.simple-header-text {
    margin-bottom: 20px;
  }

  .MuiAccordion-root {
    margin-top: 12px;
    height: auto;

    &.Mui-expanded {
      margin-top: 12px;
    }
  }

  .column-checkboxes {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      >label {
        flex-direction: row;

        >span {
          padding-left: 0;
        }
      }
    }

    margin-bottom: 12px;
  }

  .row-checkboxes {
    .MuiFormGroup-root {
      >label {
        flex-wrap: nowrap;
        display: flex;
        flex-direction: row;
      }
    }

    margin-bottom: 12px;
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
  }

  label.MuiInputLabel-root {
    font-size: 1rem;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .column-switches {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    >label {
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 75%;
    }

    >* {
      width: 60%;
    }

    >div>label {
      margin-top: 4px;
    }

    margin-bottom: 12px;
  }

  .left-grid {
    .column-checkboxes {
      padding-left: 0;

      .MuiCheckbox-root {
        margin-left: 5px;
        padding-left: 9px;
      }
    }

    >*:not(legend) {
      padding-left: 16px;
    }
  }

  .MuiCheckbox-root.Mui-checked {
    color: rgb(24, 154, 208);
  }

  .MuiAccordionSummary-root {
    >div {
      color: #000;
    }
  }

  .color-picker-modal {
    .MuiPaper-root {
      padding: 20px;
    }

    .react-colorful {
      height: 250px;

      .react-colorful__last-control {
        margin-top: 20px;
      }
    }

    .input-container {
      display: flex;
      justify-content: center;
    }

    input {
      margin-top: 20px;
    }
  }

  .color-picker-row {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
  }

  .color-picker-output {
    display: flex;
  }

  .color-picker-output {
    input {
      width: 90px;
    }
  }

  .color-picker-preview {
    background: transparent;
    border: 1px solid #eaebeb;
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
    margin-right: 5px;

    div {
      width: 25px;
      height: 25px;
    }
  }

  .color-picker-title span {
    font-weight: 500;
  }

  .light-theme {
    .color-picker-title span {
      color: #616161;
    }
  }

  #banner_type_id-select {
    margin-top: -10px;
  }

  .file-upload {
    width: 100%;
    background: transparent;
    color: #1C7BC0;
    border: 1px solid rgba(217, 217, 217, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 25px;
    border-radius: 5px;

    >img {
      margin-bottom: 12px;
    }

    >.upload-icon {
      width: 16%;
    }

    >.uploaded-logo {
      width: 75%;
    }

    &:hover {
      background-color: rgb(24, 154, 208, 0.1);
      transition: background-color 0.2s ease-in;
      cursor: pointer;
    }

    &.disabled:hover {
      background-color: transparent;
      cursor: auto;
    }
  }

  .file-upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 30px;
  }

  .remove-logo-button {
    width: fit-content;
    color: #1C7BC0;
  }

  .create-div {
    border-radius: 5px;
  }

  .MuiAccordion-root {
    box-shadow: none;
  }

  .body-content {
    padding-right: 8px;
  }

  .img-example-grid {
    width: 100%;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(233, 233, 233);
    border-radius: 4px;

    >div {
      max-width: 48%;

      >img {
        margin-top: 4px;
        max-width: 100%;
      }
    }
  }

  /* TABS */
  .MuiTabs-root {
    .MuiTab-root {
      width: 50%;
      padding-top: 12px;
      padding-bottom: 12px;
      min-height: initial;
      font-size: 13px;
    }
  }

  .MuiTabs-root.primary {
    margin-bottom: 10px;

    .MuiTab-root {
      margin: 0;
      max-width: none;
    }

    .MuiTabs-flexContainer {
      // background: red;
      border-radius: 5px;
      padding: 2px;
      background: lightgray;
      margin: 0px;

      .MuiButtonBase-root {
        border-radius: 5px;
        font-weight: bold;

        svg {
          display: none;
        }

        &.Mui-selected {
          background: #1C7BC0;
          color: white;

          svg {
            display: inline-block;
          }
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  .MuiTabs-root.secondary {
    margin-bottom: 15px;

    .MuiButtonBase-root {
      color: #686868;

      &.Mui-selected {
        color: #1C7BC0;
        border-bottom: 2px solid #1C7BC0;
        z-index: 2;
      }
    }

    .MuiTabs-indicator {
      background: #686868;
      width: 100% !important;
      left: 0 !important;
    }
  }

  .tab-helper-text {
    display: flex;
    margin-top: -5px;

    >p {
      width: 50%;
      text-align: center;
    }
  }

  /* list-view */
  .search-list-container {
    .search-list {
      min-height: fit-content;
    }

    .MuiDrawer-docked {
      .MuiPaper-root:not(.search-list-card) {
        border-right: none;
      }
    }

    .search-list-details {
      background: none;
      padding-top: 0;
      padding-left: 0;

      >button {
        margin-top: 12px;
      }
    }
  }

  .page-list-none .search-list-container .MuiGrid-root .MuiPaper-root.search-list-card {
    border: 1px solid rgba(95, 195, 237, 1);
    outline: none;
    background-color: rgba(232, 246, 252, 1);
    color: rgba(24, 154, 208, 1);
    cursor: default;

    p {
      >button {
        border: none;
        background: none;
        color: inherit;
        padding: 0;
        font-size: inherit;
        font-family: inherit;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .small-button {
      margin-top: 16px;
      padding: 0 8px;
      font-size: 0.9em;
    }
  }

  .page-list-failed .search-list-container .MuiGrid-root .MuiPaper-root.search-list-card {
    border: 1px solid rgba(250, 173, 20, 1);
    outline: none;
    background-color: rgba(255, 251, 230, 1);
    color: rgba(250, 173, 20, 1);
    cursor: default;

    .small-button {
      margin-top: 16px;
      padding: 0 12px;
      font-size: 0.9em;
      background-color: rgba(250, 173, 20, 1);
      border: none;
    }
  }

  .page-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* TYPOGRAPHY */
  legend {
    &.MuiFormLabel-root {
      font-weight: 600;
      color: colors.$grey850;
    }
  }

  .color-picker-title {
    label {
      margin: 20px 0;
    }
  }

  /* TEXT INPUTS */
  .search-list-details {
    .MuiInputBase-root {
      height: 32px;
    }
  }

  .url-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    .simple-url {
      background-color: rgba(228, 242, 248, 1);
      padding: 8px;
      border-radius: 4px;
      margin-top: 36px;
      width: 100%;

      >p {
        color: colors.$grey850;
        overflow-wrap: anywhere;
      }
    }
  }
}

.dark-theme {
  .simplepay-wrapper {
    .MuiAccordion-root {
      background-color: var(--card);
    }

    .MuiAccordionSummary-root {
      background-color: var(--container);

      >div {
        color: #fff;
      }
    }


    .search-list-container {
      > :last-child>div {
        background-color: transparent;
      }
    }

    legend {
      &.MuiFormLabel-root {
        font-weight: 600;
        color: #fff;
      }
    }

    .img-example-grid {
      background-color: transparent;
    }
  }
}

.simple-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.copy-link-container {
  display: flex;
  align-items: center;
}

.copy-link-text {
  margin-right: 8px;
}