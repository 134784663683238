.content-container {
  /* give the outermost container a predefined size */
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 2px;
  top: 0px;
  bottom: 0px;
  margin: 20px;
  margin-top: 10px;
  flex: 1 1 auto;
  width: calc(100% - 320px);
  transition: width 0.5s;
}
@media (max-width: 768px) {
  .content-container {
    top: 2.5em;
    margin: 5px;
    min-width: unset;
    max-width: unset;
    width: calc(100% - 10px);
  }
}

.paper-section {
  margin: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* for Firefox */
  min-height: 0;
  padding: 5px;
  max-width: 100% !important;
}

.header-content {
  padding: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gainsboro;
}

.body-content {
  padding-left: 5px;
  flex-grow: 1;
  overflow: auto;
  /* for Firefox */
  min-height: 0;
}

.footer-content {
  padding: 10px;
  padding-top: 10px;
  border-top: 1px solid gainsboro;
}

#main {
  padding: 0px;
  background-color: red;
}

@media screen and (min-width: 768px) {
  .miniMenu .content-container {
    width: calc(100% - 125px);
  }
}
