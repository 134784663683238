.dark-theme {
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

.mobile-search-header {
  display: none;
}

.search-button-groups__mobile {
  display: none;
}

.sortable-header {
  display: flex;

  &.is-desc {
    .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }
}

.search-paging {
  margin: 0;
  border-top: 1px solid #e0e0e0;
  padding: 1em;
  justify-content: right;
}

.desktop-search-header {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
}

.link-btn {
  background: none;
  border: none;
  color: #1C7BC0;
  text-decoration: underline;
}

@media screen and (max-width: 800px) {
  .mobile-search-header {
    display: block;
    font-weight: 700;
  }

  .search-button-groups__mobile {
    display: block;
  }

  .desktop-search-header {
    display: none;
  }

  .search-button-groups__desktop {
    display: none;
  }

  .table-format-dropdown {
    flex-basis: 100%;
    margin-bottom: 10px;
  }

  .date-search {
    > .MuiGrid-root:first-child {
      flex-basis: 100%;
      margin-bottom: 10px;
    }

    .date-range-fields {
      .MuiFormControl-root {
        width: 40%;
      }
    }
  }

  .search-button-groups {
    flex-direction: column;

    .MuiGrid-root {
      padding-bottom: 5px;
    }
  }
}
