.modal-button-nav {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

.box-style {
  padding: inherit;
  padding: 30px;
  width: 100%;
  max-width: 1000px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-icon,
.settlements-open-icon {
  cursor: pointer;
}

.settlements-modal-wrapper {
  .box-style {
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #000;
  }
  .transaction-pdf-table {
    width: 1200px;
  }
}

.scroll-table {
  max-height: 300px;
  overflow-y: scroll;
}
.circular-spinner {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.table-margin-bottom {
  border-bottom: solid 1px rgba(224, 224, 224, 1);
  margin-bottom: 30px;
}

.transaction-modal-button {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 0;
  font-size: 20px;
  display: flex;
  &:first-of-type {
    border-right: 2px solid var(--primary-blue);
  }
  span {
    text-decoration: underline;
  }
}

.settlements-dl-button {
  float: right;
}

.summary-table-wrapper {
  position: relative;
  .sticky-table {
    .MuiTableContainer-root {
      overflow-x: initial;
    }

    tfoot {
      position: sticky;
      bottom: -1px;
    }
  }
  tfoot {
    background: #e6f3fa;
    td {
      font-weight: 600;
      color: black;
      font-size: 16px;
      padding: 12px 8px;
      &:nth-of-type(3) {
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

.tabs-wrapper {
  min-width: max-content;
  .stickyTabs {
    position: sticky;
    z-index: 1001;
    top: 0px;
  }
  .MuiTabs-flexContainer {
    align-items: center;
    justify-content: end;
  }
  .download-button {
    height: 100%;
    flex-grow: 1;
    text-align: right;
    margin-right: 5px;
    padding-top: 10px;
  }
}
.csv-button {
  margin-bottom: 10px;
}

.pdf-table {
  position: absolute;
  left: -9999px;
  top: -9999px;
  padding: 120px 50px 0px 50px;
  .header-row {
    margin-bottom: 120px;

    div {
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: center;

      img {
        width: 180px;
        margin-bottom: 25px;
      }
    }
    h2 {
      text-align: center;
      display: block;
      margin: 0;
      font-weight: 550;
      font-size: 34px;
      color: #01579b;
      font-family: "Plus Jakarta Sans", "Roboto", "Helvetica", "Arial",
        sans-serif;
    }
  }
  .sortable-header .MuiSvgIcon-root {
    display: none;
  }
  th {
    position: initial;
  }
  table td,
  th,
  tfoot td {
    color: black;
    font-size: 24px;
    padding: 16px;
    border-color: darkgray;
  }
  .MuiTableCell-root.MuiTableCell-head {
    background-color: white;
  }
  tbody tr:nth-child(32) {
    td {
      padding-bottom: 150px;
      border-bottom: none;
    }
  }
  tbody tr:nth-child(27n + 49) {
    td {
      padding-bottom: 80px;
      border-bottom: none;
    }
  }
}
