.reset-page-container {
  .tos-header {
    font-size: 25px;
  }
  .tos-time {
    font-size: 14px;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .tos-button {
    width: fit-content;
    .muiButton-root {
      .muiSvgIcon-root {
        padding-right: 5px;
      }
    }
  }
  .permissions-notify {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    p {
      font-size: 15px;
      margin: 0px;
    }
    background: rgba(2, 136, 209, 0.1);
    .permissions-copybody {
      margin-left: 15px;
    }
  }
}
