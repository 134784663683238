@import "../../../../assets/v2/_colors.scss";
@import "../../../../assets/v2/variables.scss";

.pt-wrapper {
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: var(--primary-typography);
  }
  display: flex;
  justify-content: space-between;
  > div {
    width: 48%;
    justify-content: space-between;
  }
  @include breakpoint("medium") {
    flex-wrap: wrap;
    > div {
      width: 100%;
    }
  }
}
.pt-list {
  // width: 45%;
  .list-wrapper {
    overflow-y: auto;
    height: 400px;
    border-bottom: 1px solid #d9d9d9;
    &::-webkit-scrollbar {
      display: none;
    }
    @include breakpoint("medium") {
      height: auto;
      display: flex;
      flex-direction: row;
      button {
        margin: 12px 6px;
        .button-header {
          align-items: start;
          flex-direction: column;
        }
      }
    }
  }
}
.terminal-button {
  width: 100%;
  text-align: initial;
  padding: 12px 20px;
  cursor: pointer;
  margin: 12px 0px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #777474;
  span {
    display: block;
    margin-bottom: 2px;
  }
  .button-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      font-size: 16px;
      margin-bottom: spacer(2);
      &:first-of-type {
        font-weight: 600;
      }
    }
  }

  .terminal-status {
    position: relative;
    padding-right: 24px;
    &:after {
      content: " ";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: red;
      top: 5px;
      right: 4px;
    }
  }

  &:hover {
    background: darken(#f5f5f5, 10%);
  }
  &.active {
    .terminal-status {
      &:after {
        background: #52c41a;
      }
    }
  }
  &.busy {
    .terminal-status {
      &:after {
        background: #fadb14;
      }
    }
  }
  &.offline {
    opacity: 0.6;
    pointer-events: none;
  }
  &.selected {
    border: 2px solid color(neutrals, 500);
  }
}

.pt-summary {
  color: var(--primary-typography);
  button {
    width: 100%;
  }
  .totals-section {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: spacer(4);
    margin-bottom: spacer(4);
    margin-top: spacer(4);
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .button-toggle {
    margin-bottom: 16px;
  }
  .btn--secondary {
    margin-top: 16px;
  }
}

.pt-stepper-wrapper {
  .stepper-dot {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: color(blue, 300);
    display: flex;
    align-items: center;
    transition: all 250ms;
    &::after {
      content: attr(title);
      display: block;
      width: 100%;
      position: absolute;
      color: var(--primary-typography);
      font-size: 14px;
      left: 15%;
    }
    &.active {
      font-size: 20px;
      color: color(blue, base);
      width: 50px;
      height: 50px;
      border-radius: 30px;
      background: color(blue, base);
      &::after {
        font-size: 20px;
        color: color(blue, base);
      }
    }
    &.complete {
      width: 25px;
      height: 25px;
      border-radius: 30px;
      background: color(neutrals, 500);
    }
    @include breakpoint("medium") {
      &::after {
        content: initial;
      }
    }
  }
  .stepper-text {
    color: var(--primary-typography);
    font-size: 14px;
  }
  .dots-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    width: 50px;
    @include breakpoint("medium") {
      width: 100%;
      flex-direction: row;
      height: 50px;
    }
  }
  .timeline-bar {
    height: 100%;
    width: 2px;
    background-color: color(blue, 300);
    position: absolute;
    top: 0;
    left: 24px;
    z-index: 1;
    @include breakpoint("medium") {
      height: 2px;
      width: 100%;
      top: 24px;
      left: 0px;
    }
  }

  .pt-step {
    height: 100%;
    position: relative;
    display: flex;
  }
}

.connection-summary {
  padding: spacer(15) 0px;
  @include breakpoint("medium") {
    padding: spacer(7);
  }
  .terminal-name {
    margin-bottom: spacer(5);
  }
  button {
    margin-top: spacer(5);
  }
}

p.mobile-text-stepper {
  display: none;
  text-align: center;
  font-size: 20px;
  color: color(blue, base);
  margin-bottom: spacer(3);
  @include breakpoint("medium") {
    display: block;
  }
}
