@import "../../../../assets/v2/variables";
@import "../../../../assets/v2/colors";
@import "../../../../assets/v2/typography";
.blue-switches {
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: color(blue, base);
  }
  .MuiSwitch-switchBase.Mui-checked {
    color: color(blue, base);
    &:hover {
      background-color: rgba(24, 154, 208, 0.04);
    }
  }
}

.gateway-badge {
  width: padding;
  padding: 4px 8px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  display: inline-block;
  text-transform: lowercase;
  font-weight: 600;
  &.active {
    background: #54bee1;
  }
  &.disabled {
    background: red;
  }
  &.invite_pending {
    background: #01579b;
  }
  &.pending_tos {
    color: #faad14;
    background-color: #fffbe6;
  }
}

.user-detail-text {
  word-break: break-word;
}

.dark-theme {
  .gateway-backlist-button {
    color: #39A6FF;
  }
  .gateway-badge {
    &.pending_tos {
      color: white;
      background-color: #461d02;
    }
  }
}

.styled-tabs {
  padding: 20px;
}

.gateway-user-detail {
  width: 80%;
  .MuiFormControl-root {
    width: 100%;
  }
  .gateway-user-header-system {
    display: flex;
    margin-left: -15px;
  }
  .button-row {
    .copy-btn {
      margin-right: 15px;
    }
    &.desktop {
      display: flex;
    }
    &.mobile {
      display: none;
    }
    flex-wrap: wrap;
    > button {
      margin-top: 10px;
    }
  }

  .toggle-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      width: 45%;
    }
    .MuiFormControlLabel-root {
      justify-content: space-between;
      width: 100%;
      margin-left: 0px;
      margin-bottom: 15px;
    }
  }
}

.email-lookup-wrapper {
  width: 40%;
  margin-bottom: 10px;
  .btn-row {
    padding-left: 10px;
  }
}

.lookup-form {
  display: flex;
}

.user-details-save {
  margin-top: 10px;
  button:first-of-type {
    margin-right: 10px;
  }
}

.permissions-notification {
  display: flex;
  align-items: flex-start;
  width: 55%;
  padding: 20px;
  margin-top: 1em;
  .permission-banner-heading {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    margin-bottom: 0px;
  }
  background: rgba(2, 136, 209, 0.1);
  .permissions-body {
    margin-left: 15px;
  }
}

.create-detail-wrapper {
  .create-header {
    display: flex;
    align-items: center;
  }

  .create-details-body {
    .form-elements {
      width: 80%;
    }
    .create-permissions-wrapper {
      width: 100%;
      .permissions-notification {
        width: 100%;
      }
    }

    .create-name-fields {
      display: flex;
      justify-content: space-between;
      .MuiFormControl-root {
        width: 49%;
      }
    }
    .create-permissions-wrapper {
      h3 {
        margin: revert;
        margin-bottom: 15px;
      }
      .toggle-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 80%;
        ul {
          padding: 0px;
          list-style-type: none;
          margin: 0px;
          width: 45%;
        }
        .MuiFormControlLabel-root {
          justify-content: space-between;
          width: 100%;
          margin-left: 0px;
          margin-bottom: 15px;
        }
      }
    }
    .user-help-text {
      font-size: 14px;
      margin-top: -15px;
      color: #0885c9;
      margin-bottom: 5px;
      &.dark-theme {
        color: #39A6FF;
      }
    }
    .btn-row {
      button {
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .create-detail-wrapper {
    .create-details-body {
      .form-elements {
        width: 100%;
      }
      .create-permissions-wrapper {
        width: 100%;
        .toggle-container {
          width: 100%;
          ul {
            width: 100%;
          }
          .MuiFormControlLabel-root {
            width: 100%;
          }
        }
      }
    }
  }
  .gateway-user-detail {
    .user-details-wrapper {
      .details-section,
      .permissions-section {
        width: 100%;
      }
      .permissions-section {
        margin-top: 0px;
      }
      .toggle-container {
        width: 100%;
        ul {
          width: 100%;
        }
        .MuiFormControlLabel-root {
          width: 100%;
        }
      }
    }
    .button-row {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
      }
      .user-details-save {
        button {
          width: 100%;
          &:first-of-type {
            margin-bottom: 10px;
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .user-details-wrapper {
    width: 100%;
  }
  .create-detail-wrapper {
    .btn-row {
      .MuiButton-root {
        width: 100%;
      }
    }
    .create-details-body {
      .form-elements {
        width: 100%;
      }
    }
  }
  .email-lookup-container {
    flex-direction: column;
    .email-lookup-wrapper {
      width: 50%;
    }

    .permissions-notification {
      width: 100%;
    }
  }
}
@media screen and (max-width: 600px) {
  .email-lookup-container {
    .email-lookup-wrapper {
      width: 100%;
    }
  }
}

.gateway-user-container {
  .MuiFormControlLabel-root {
    width: 100%;
    justify-content: space-between;
    margin: 0px;
  }

  .user-profile {
    background-color: color(blue, 100);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .card-obj {
    &.mfa {
      .MuiFormControlLabel-root {
        width: initial;
      }
    }
    .MuiGrid-root {
      margin-bottom: spacer(2);
    }
  }

  .mfa-label {
    @extend .h5;
    @extend .blue-switches;
  }
  .permission-switches {
    @extend .blue-switches;
  }
  .table {
    .MuiTableCell-head {
      @extend .sub-2;
    }

    .MuiTableCell-root {
      max-width: spacer(60);
      text-align: left;
      word-break: break-all;
    }
    border: 1px solid color(neutrals, 300);
    border-radius: spacer(1);
  }
}

.dark-theme {
  .user-profile {
    background-color: color(neutrals, 700);
  }
}
