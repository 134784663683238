@import "../../assets/colors.module.scss";
.ach-form {
  display: flex;
  flex-wrap: wrap;
}

.ach-input {
  input {
    color: #666;
    height: 30px;
    padding: 5px 10px;
    font-size: 13px;
    &.light-theme {
      border: 2px solid $blue550;
    }

    &.dark-theme {
      background-color: $grey850;
      color: $grey600;
      border: 1px solid $grey500;

      &:focus {
        border: 2px solid $blue300;
      }
    }
  }
}

.ach-select {
  select {
    &.light-theme {
      &:focus {
        border: 2px solid $blue550;
      }
    }

    &.dark-theme {
      background-color: $grey850;

      &:focus {
        border: 2px solid $blue300;
      }
    }

    padding: 5px 5px 5px 10px;
  }
}

.account {
  margin-bottom: 10px;
  flex: 1 0 40%;
  padding-right: 5px !important;
  order: 1;
}

.routing {
  margin-bottom: 10px;
  flex: 1 0 40%;
  order: 2;
}

.type {
  margin-bottom: 10px;
  flex: 1 0 40%;
  padding-right: 5px !important;
  order: 4;
  .MuiOutlinedInput-root {
    width: 100%;
  }
}

.sec {
  margin-bottom: 10px;
  flex: 1 0 40%;
  order: 5;
  .MuiOutlinedInput-root {
    width: 100%;
  }
}

.dark-theme {
  .ach-input {
    input {
      color: white;
    }
  }
}
.check {
  margin-bottom: 10px;
  flex: 1 0 100%;
  order: 3;

  .MuiOutlinedInput-root {
    width: 100%;
  }
}
