/* ===============
   Data Entry
   =============== */

/*** Text Input ***/
.MuiInputLabel-root {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 500;
  transform: none;
  position: unset;
}
.MuiInputBase-root:not(.multi-select) {
  height: 24px;
  input {
    padding: 8px;
  }
}

.MuiFormControlLabel-label {
  font-size: 14px;
}

.MuiInputBase-multiline {
  height: auto;
}

textarea {
  border-color: color(neutrals, 300);
  width: 100%;
  padding-top: spacer(2);
  border-radius: spacer(1);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-left: spacer(2);
}

@at-root .dark-theme {
  textarea {
    background: inherit;
    color: white;
  }
}
