// Remove imports after converting to v2
@import "../../../assets/v2/variables";
@import "../../../assets/v2/colors";

.gateway-badge {
  width: padding;
  padding: 4px 8px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  display: inline-block;
  text-transform: lowercase;
  font-weight: 600;
  &.active {
    background: #54bee1;
  }
  &.disabled {
    background: red;
  }
  &.invite_pending {
    background: #01579b;
  }
}

.edit-user-btn {
  &:disabled {
    pointer-events: auto;
    color: color(neutrals, 300);
    @include darkTheme {
      color: color(neutrals, 500);
    }
  }
}

.styled-tabs {
  padding: 20px;
}
.gateway-manage-users {
  .gateway-user-detail {
    .gateway-user-header {
      display: flex;
    }
  }

  .email-lookup-wrapper {
    width: 55%;
    .btn-row {
      display: flex;
      margin-top: 10px;
    }
  }

  .permissions-notification {
    display: flex;
    align-items: flex-start;
    width: 55%;
    padding: 20px;
    margin-top: 1em;

    p {
      font-size: 14px;
      margin-bottom: 0px;
    }
    background: rgba(2, 136, 209, 0.1);
    .permissions-body {
      margin-left: 15px;
      h2 {
        margin: 0px;
        font-size: 16px;
      }
    }
  }

  .create-detail-wrapper {
    .create-header {
      display: flex;
      align-items: center;
    }

    .create-details-body {
      .user-help-text {
        font-size: 14px;
        margin-top: -15px;
        color: #0885c9;
        margin-bottom: 5px;
        &.dark-theme {
          color: #39a6ff;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .create-detail-wrapper {
      .create-details-body {
        .form-elements {
          width: 100%;
        }
        .create-permissions-wrapper {
          width: 100%;
        }
      }
    }
    .gateway-user-detail {
      width: 100%;
      .user-details-wrapper {
        flex-wrap: wrap;
        .details-section,
        .permissions-section {
          width: 100%;
        }
        .permissions-section {
          margin-top: 0px;
        }
      }
      .form-buttons {
        .MuiButton-root {
          width: 100%;
          &:first-of-type {
            margin-right: 0px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .create-detail-wrapper {
      .btn-row {
        .MuiButton-root {
          width: 100%;
        }
      }
    }
    .email-lookup-container {
      flex-direction: column;
      .email-lookup-wrapper {
        width: 100%;
      }

      .permissions-notification {
        width: 100%;
      }
    }
  }
}
