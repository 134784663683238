@use "sass:map";
@import "../../../assets/v2/_colors.scss";
@import "../../../assets/v2/variables.scss";

$search-list-light: (
  "background": color(neutrals, 100),
  "card-background": var(--card),
  "card-border": color(blue, base),
  "card-outline": #cde7f2,
);
$search-list-dark: (
  "background": var(--container),
  "card-background": var(--card),
  "card-border": color(blue, base),
  "card-outline": #2d4b5e,
);

.filter-drawer {
  padding: 1em;
  width: 25%;
  border-radius: 4px;
  bottom: 0;
  height: auto;

  .filter-close-button-container {
    padding: 0;
    justify-content: flex-end;

    .filter-close-button {
      position: fixed;
      top: 0.25em;
    }
  }

  .MuiDivider-root {
    margin: 1.5em 0 1.5em 0;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.12);
  }

  .filter-inputs {
    overflow-y: auto;
    padding-right: 1em;
    margin-bottom: 1em;
  }

  #reset-button {
    color: color(neutrals, 500);
    margin-right: 1em;
  }
}

.search-list-sort-menu {
  .MuiPaper-root {
    padding: 0.5em;
  }

  .MuiDivider-root {
    margin-left: 0;
    margin-right: 0;
    background-color: rgba(0, 0, 0, 0.12);
  }

  &.dark-theme {
    .MuiButtonGroup-root {
      .MuiButton-root {
        color: white;

        &.selected {
          color: color(blue, base);
        }
      }
    }
  }
}

.search-list-container {
  width: 100%;
  overflow-x: hidden;
  margin-left: 0;
  margin-top: 0;

  .MuiDrawer-docked {
    width: 100%;
    height: 100%;

    .MuiPaper-root {
      position: relative;
      height: 100%;
    }
  }

  .search-list-container {
    padding-left: 0;
  }

  .search-list-filter-container {
    background-color: map-get($map: $search-list-light, $key: "background");
    border-radius: 4px;
    padding: 0.25em;
    margin-bottom: 1em;

    form {
      width: 100%;
    }

    .search-list-input {
      margin: 0.25em;
    }

    .search-list-filter-field {
      background-color: map-get(
        $map: $search-list-light,
        $key: "card-background"
      );
      margin: 0.25em;
      width: 150px;

      .MuiInputLabel-root {
        position: absolute;
        transform: translate(8px, 12px) scale(1);
      }

      .MuiSelect-select {
        padding-left: 8px;
      }

      .MuiSvgIcon-root {
        opacity: 0.5;
      }
    }

    .search-list-filter-button {
      &#all-filters-button {
        color: color(neutrals, 500);
      }
      &.MuiButton-root {
        margin: 0.25em;
        line-height: 1;
      }
    }
  }

  .search-list {
    width: 100%;
    min-height: 1000px;
    background-color: map-get($map: $search-list-light, $key: "background");
    overflow-y: auto;
    //scrollbar-width: none;
    border-radius: 4px;

    #search-list-title {
      padding: 0.5em;
    }

    .search-list-input-form {
      width: calc(100% - 80px);
    }

    .search-list-card {
      background-color: map-get(
        $map: $search-list-light,
        $key: "card-background"
      );
      background-image: none;
      padding: 0.5em;
      margin: 0.75em;
      height: auto;
      box-shadow: none;
      cursor: pointer;

      &.active {
        border: 2px solid map-get($map: $search-list-light, $key: "card-border");
        outline: 2px solid
          map-get($map: $search-list-light, $key: "card-outline");
      }

      .card-content {
        margin: 0.5em 0;
      }

      .card-corner {
        font-size: 0.75rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .card-corner:nth-child(2),
      .card-corner:nth-child(5) {
        text-align: right;
      }
    }
  }

  .search-list-input-container {
    padding: 0.5em 0.75em;
  }

  .search-list-input {
    background-color: map-get(
      $map: $search-list-light,
      $key: "card-background"
    );
    height: 24px;
    width: 177px;

    .MuiInputBase-root {
      padding-right: 0;
    }

    .search-list-input-button {
      background-color: map-get(
        $map: $search-list-light,
        $key: "card-background"
      );
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.23);
      min-width: 0;
      height: 24px;
    }
  }

  .search-list-details-container {
    min-height: 700px;
  }

  .search-list-details {
    background-color: map-get($map: $search-list-light, $key: "background");
    border-radius: 4px;
    padding: 0.75em;
  }

  .search-list-loader {
    display: flex;

    .loader-icon {
      margin-left: 1em;
    }
  }
}

.dark-theme {
  .search-list-container {
    .search-list-filter-container {
      background-color: map-get($map: $search-list-dark, $key: "background");
    }

    .search-list {
      background-color: map-get($map: $search-list-dark, $key: "background");

      .search-list-card {
        background-color: map-get(
          $map: $search-list-dark,
          $key: "card-background"
        );

        &.active {
          border: 2px solid
            map-get($map: $search-list-dark, $key: "card-border");
          outline: 2px solid
            map-get($map: $search-list-dark, $key: "card-outline");
        }
      }
    }

    .search-list-input {
      color: color(neutrals, white);
      background-color: map-get(
        $map: $search-list-dark,
        $key: "card-background"
      );
      //border: 1px solid color(neutrals, white);
      border-radius: 4px;

      .search-list-input-button {
        //background-color: color(neutrals, white);
        border-left: 1px solid rgba(255, 255, 255, 0.23);

        .MuiSvgIcon-root {
          color: rgba(255, 255, 255, 0.23);
        }
      }
    }

    .search-list-details {
      background-color: map-get($map: $search-list-dark, $key: "background");
    }
  }
}

.dark {
  .filter-drawer {
    .MuiDivider-root {
      background-color: transparent;
      border-color: color(neutrals, 500);
    }
  }
}

@media screen and (max-width: map.get($breakpoints, "medium")) {
  .filter-drawer {
    width: 50%;
  }
}

@media screen and (max-width: map.get($breakpoints, "small")) {
  .filter-drawer {
    width: 100%;
    margin: 0;
  }

  .search-list-filter-container {
    display: none;
  }

  .search-list-container {
    height: auto;

    .search-list-details-container {
      padding-left: 0;
    }
  }
}
