.back-arrow {
  display: flex;
  color: #1C7BC0;
  text-decoration: none;
  cursor: pointer;
  margin-left: -10px;
}

.create-detail-wrapper.system-user {
  .create-header {
    display: flex;
    align-items: center;
  }
  .create-details-body {
    .form-elements {
      width: 49%;
      .MuiFormControl-root {
        margin-bottom: 15px;
      }
      .create-name-fields {
        display: flex;
        justify-content: space-between;
        .MuiFormControl-root {
          width: 49%;
        }
      }
    }
  }

  .system-user-btn-row {
    &.mobile {
      display: none;
    }
    &.desktop {
      display: flex;
    }
  }

  .create-details-body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 15px;
    .create-permissions-wrapper {
      h3 {
        margin: 0px;
      }
      width: 35%;
      display: flex;
      flex-direction: column;
      .MuiFormControlLabel-root {
        justify-content: space-between;
        margin-left: 0px;
        margin-bottom: 15px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    // .create-detail-wrapper.system-user {
    .create-details-body {
      .form-elements {
        width: 100%;
      }
      .create-permissions-wrapper {
        width: 100%;
      }
    }
  }
  .system-user-btn-row {
    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }
  // }
}
