@use "../../../assets/colors.module.scss" as colors;
@import "../../../assets/v2/colors.scss";

.gateway-info {
  padding: 1em 0.5em;
}

.gateway-info p {
  margin: 0;
  line-height: 1.3;
}

.update-address-button {
  margin-top: 0.5em;
}

.remittance-drawer {
  padding: 1em;
  max-width: 50%;
}

.remittance-drawer .table-customer-wrapper {
  padding: 2em;

  th {
    background-color: transparent;
  }

  .MuiTableBody-root .MuiTableCell-root {
    padding: 1em 0.5em;
  }

  .customer-search-field {
    margin-bottom: 2em;
  }

  .customer-search-button {
    padding-left: 1em;
  }

  .customer-pagination-vt .search-paging {
    padding-top: 3em;
    justify-content: space-between;
  }
}

.remittance-heading,
.payment-types-heading,
.invoice-total,
.invoice-section-heading {
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1em;
}

.invoice-section-heading {
  margin-bottom: 0;
  padding-left: 0.5em;
}

.remittance-close-button {
  position: fixed;
  top: 0;
  right: 0;
}

.remittance-form {
  padding: 0 1.5em;
}

.remittance-buttons {
  margin-top: 2em;
}

.edit-button {
  display: inline-flex;
}

.invoice-container,
.invoice-edit-container {
  width: 90%;
  padding: 2em 1em;

  p.disabled {
    color: #888;
  }
}

.invoice-edit-container {
  width: 95%;
  align-items: flex-start;

  .invoice-select-customer {
    background-color: colors.$system_white;
    z-index: 100;
    position: relative;
    padding-top: 2em;
    margin-left: 0.5em;
    margin-right: 1em;
    margin-top: 1.5em;
    box-shadow: 0 -10px 10px 3px #0000001f;
    width: calc(100% - 0.5em);
  }

  .invoice-tabs {
    box-shadow: 0 -7px 10px 3px #0000001f;
    margin-top: 0;
  }

  .invoice-additional-info,
  .payment-types {
    margin-top: -28px;
    box-shadow: 0 10px 10px 3px #0000001f;
    background-color: colors.$system_white;
  }

  .invoice-communication-container {
    align-items: center;

    .invoice-communication-menu .MuiInput-root {
      margin-top: 0;
    }

    .add-additional-recipients-button-container {
      padding-top: 0;
    }
  }

  input {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .invoice-details,
  .payment-types {
    padding-left: 2em;
    padding-right: 2em;
  }

  .invoice-details {
    box-shadow: 0 -7px 10px 3px #0000001f;
    padding-top: 2em !important;
  }

  .payment-types {
    padding-bottom: 2.75em;
  }

  .invoice-payment-type-toggle:nth-child(4) {
    margin-left: 0;
    padding-left: 15%;
    padding-right: 0;
  }

  .line-item-container.disabled {
    background-color: #ececec;
    border: none;
    box-shadow: none;

    p {
      color: #888;
    }
  }
}

.invoice-select-customer {
  padding: 3em 0 1em 3em;

  button {
    color: #4c5b8c;
    background-color: colors.$lightblue250;
    font-size: 16px;
    font-weight: bold;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  }

  button:hover {
    background-color: colors.$lightblue250;
    filter: brightness(95%);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  }

  .MuiButton-startIcon svg {
    font-size: 32px;
    fill: #4c5b8c;
  }
}

.invoice-customer-name {
  display: inline;
  font-size: 0.9rem;
  padding-left: 1em;
}

.invoice-tabs,
.invoice-section,
.invoice-additional-info {
  margin: 2em 0 0 1em;
  box-shadow: 0 0 10px 3px #0000001f;
  padding-bottom: 2em;
}

.invoice-tabs .MuiTab-root {
  padding: 0 4em;
}

.invoice-tabs .invoice-checkbox {
  float: right;
}

.invoice-contact-form {
  padding-right: 0.5em;
}

.copy-address-container {
  padding-top: 0.5em;
}

.invoice-checkbox {
  margin-bottom: 1em;
}

.invoice-checkbox span {
  font-size: 0.8rem;
}

.invoice-additional-info .invoice-checkbox {
  padding-left: 1em;
}

.invoice-communication-container {
  padding: 0 0.5em;
}

.invoice-communication-menu {
  margin-bottom: 0.5em;
}

.invoice-communication-menu-label {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0;
}

.add-additional-recipients-button {
  font-size: 0.9rem;

  &:disabled {
    cursor: auto;
    color: colors.$grey500;
  }
}

.additional-recipients {
  margin-bottom: 0.75em;
}

.next-page-button {
  margin: 2em 0 0 0.5em;
}

.invoice-section {
  padding-top: 1em !important;
}

.invoice-payment-type-toggle {
  align-content: start;
}

.invoice-payment-type-toggle:nth-child(4) {
  margin-left: 4em;
}

.invoice-payment-type-toggle label {
  margin-left: 0;
}

.invoice-payment-type-toggle .no-ach-processor {
  overflow: hidden;
}

.invoice-message {
  white-space: pre-wrap;
}

.invoice-message .MuiInputBase-multiline {
  height: auto;
}

.label-with-tooltip-label {
  display: inline-block;
  margin-right: 4px;
}

.label-with-tooltip-icon {
  vertical-align: sub;
  color: #7e57c2;

  &.v2 {
    color: color(blue, base);
  }
}

.v2 {
  .line-item-container {
    border-radius: 5px;
    box-shadow: none;
  }

  .invoice-actions-container {
    .invoice-calculation-container {
      margin: 0;
      width: 100%;

      .invoice-totals-labels {
        padding-left: 0;

        p {
          text-align: left;
        }
      }
    }
  }

  .invoice-total-container {
    padding-right: 0;
  }
}

.light-theme {
  .label-with-tooltip-required label {
    background-color: colors.$system_white;
  }

  .line-item-container {
    border-color: colors.$blue300;
    background-color: colors.$lightblue250;

    .MuiInputBase-root {
      background-color: colors.$system_white;
    }
  }

  .invoice-create-menu .MuiInputBase-root {
    background-color: colors.$blue300;
  }
}

.dark-theme {
  .label-with-tooltip-required label {
    background-color: colors.$system_dark;
  }

  .line-item-container {
    border-color: colors.$blue550;
    background-color: colors.$system_darkblue;
  }

  .invoice-create-menu {
    .MuiInputBase-root {
      background-color: colors.$blue550;
    }

    .MuiInputBase-root.Mui-disabled {
      background-color: rgba(255, 255, 255, 0.12);
    }

    .MuiInputLabel-root.Mui-disabled {
      color: rgba(255, 255, 255, 0.3);
      -webkit-text-fill-color: rgba(255, 255, 255, 0.3) !important;
    }

    .Mui-disabled {
      .MuiSvgIcon-root,
      .MuiInputBase-root {
        color: rgba(255, 255, 255, 0.3);
      }
    }

    .add-additional-recipients-button:disabled {
      color: colors.$grey600;
    }
  }

  .invoice-edit-container .invoice-tabs,
  .invoice-edit-container .invoice-additional-info,
  .invoice-edit-container .payment-types,
  .invoice-edit-container .invoice-select-customer {
    background-color: #1e1e1e;
  }

  .invoice-edit-container .line-item-container.disabled {
    background-color: colors.$system_darkblue;
  }
}

.label-with-tooltip-required label {
  padding: 0 5px;
}

.label-with-tooltip-required legend {
  display: none;
}

.label-with-tooltip-required fieldset {
  top: 0;
}

legend .label-with-tooltip-label {
  margin-right: 0;
}

legend .label-with-tooltip-icon {
  display: none;
}

.MuiDrawer-root label {
  background-color: transparent;
}

.line-item-container {
  padding: 0.75em 0.75em 0.75em 1.5em;
  margin-bottom: 0.5em;
  border: 1px solid;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);

  p {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  input[type="number"] {
    text-align: center;
  }

  .line-item-info {
    max-width: 50%;
  }

  .MuiFormControlLabel-label {
    font-size: 0.9rem;
  }

  .line-item-delete-label {
    display: inline;
    font-size: 0.9rem;
    margin-right: 0.5em;
  }

  .line-item-delete-label + button {
    padding: 0;
  }

  .MuiFormHelperText-root {
    overflow: visible;
  }

  .line-item-tax-and-total-container {
    justify-content: space-between;
    margin-top: 0.5em;

    .MuiGrid-item {
      width: auto;
    }

    .line-item-total-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .line-item-total {
        padding-top: 6px;
      }
    }
  }
}

.invoice-actions-container {
  justify-content: space-between;
  margin-top: 1em;

  .MuiGrid-item {
    width: auto;
  }

  .invoice-calculation-container {
    margin: 0 0.75em 1em auto;
    border-bottom: 1px solid #ddd;

    p {
      text-align: right;
      margin: 0 0 0.5em;
      line-height: 1;
    }
  }
}

.invoice-total-container {
  flex-direction: row-reverse;
  padding-right: 0.75em;

  a {
    cursor: pointer;
  }

  .original-balance {
    margin: -0.5em 0 0 0;
    text-align: right;
  }

  .invoice-total {
    text-align: right;
    margin-bottom: 0;
    font-size: 1.25rem;
  }
}

.invoice-create-menu {
  .MuiInputLabel-root {
    line-height: 1.8em;
    color: colors.$system_white;
    -webkit-text-fill-color: #fff !important;
    transform: translate(14px, 16px) scale(1);
    transform-origin: top left;
    margin-top: -10px;
  }

  .MuiInputBase-root {
    height: 36.5px;
    color: colors.$system_white;
  }

  .MuiSvgIcon-root {
    color: colors.$system_white;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiInputLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.26) !important;
  }

  .MuiInputBase-root.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .Mui-disabled {
    .MuiSvgIcon-root,
    .MuiInputBase-root {
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

.link-label {
  margin-top: 0;
  transform: translate(0, 1.5px) scale(0.75);
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  .remittance-drawer {
    max-width: 100%;
  }

  .invoice-container,
  .invoice-edit-container {
    width: 100%;
  }

  .invoice-tabs .invoice-checkbox {
    float: none;
  }

  .invoice-payment-type-toggle:nth-child(3) {
    border: none;
  }

  .invoice-payment-type-toggle:nth-child(4) {
    margin-left: 0;
  }

  .invoice-actions-container {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1060px) {
  .invoice-edit-container {
    .MuiGrid-grid-md-6 {
      flex-basis: 100%;
      max-width: 100%;
    }

    .invoice-payment-type-toggle:nth-child(3) {
      border: none;
    }

    .invoice-payment-type-toggle:nth-child(4) {
      padding-left: 0;
      margin-left: 0;
    }

    .invoice-total-container .MuiGrid-container {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
