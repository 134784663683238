@mixin override-color {
  color: #1C7BC0 !important;
  font-weight: 800 !important;
}

.global-search-div {
  .global-search-input {
    display: inline-flex;
    flex: 1;

    .MuiInputBase-root {
      padding: 0px 5px 0px 5px;
      border-radius: 0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 40px;
    }
  }

  .global-search-button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
  }
}

.filter-btn {
  display: flex;
  cursor: pointer;

  .MuiSvgIcon-root {
    margin: 0 5px;
    align-self: center;
    font-size: 16px;
  }

  &:hover {
    color: #1C7BC0;
  }
}

.calendar-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-x: auto;

  .default-date-btns {
    justify-content: space-evenly;
    flex-wrap: wrap;

    button {
      margin-bottom: 0.5em;
    }
  }

  .date-range-picker {
    margin: auto;

    .rdrDateDisplayWrapper {
      background-color: transparent;
    }

    .rdrCalendarWrapper {
      background-color: transparent;
    }

    .rdrDays {
      .rdrDay {
        .rdrDayNumber {
          span {
            font-weight: 600;
          }
        }
      }

      button {
        padding: 20px;
      }
    }

    .rdrDayHovered {
      &:hover {
        border-style: none !important;
        border-color: transparent;
      }
    }

    .rdrDayStartPreview {
      border-style: solid;
      border-color: #0000004d !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .rdrDayEndPreview {
      border-style: solid;
      border-color: #0000004d !important;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .rdrDayInPreview {
      border-style: solid;
      border-color: #0000004d !important;
      border-radius: 0px;
    }

    .rdrStartEdge {
      @include override-color();
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .rdrInRange {
      @include override-color();
    }

    .rdrEndEdge {
      @include override-color();
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .rdrDateDisplayItemActive {
      border: none;
      border-bottom: 4px solid #1C7BC0;
    }

    .rdrDateDisplayItem {
      input {
        color: black;
      }
    }
  }
}

.menu-checked {
  margin: auto;
}

.dark-theme {
  .calendar-container {
    .default-date-btns {
      button {
        color: white;
      }
    }

    .date-range-picker {
      .rdrMonthAndYearWrapper {
        select {
          color: white;
        }
      }

      .rdrDateDisplayItem {
        background-color: transparent;

        input {
          color: white;
        }
      }

      .rdrDayNumber {
        span {
          color: white;
        }
      }
    }
  }
}
