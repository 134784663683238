@mixin sub-custom-field-values-table {
  th {
    font-size: 12px;
    border: none;
  }

  td {
    border: none;
    max-width: 50px;
    word-wrap: break-word;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #616161;
    }
  }

  .light-theme {
    tbody {
      tr:nth-child(odd) {
        background-color: #1e74b6;
      }
    }
  }
}

.create-custom-container {
  .body-content {
    overflow: initial;
    min-height: initial;
  }
  .MuiPaper-root {
    min-height: fit-content;
  }
}

.empty-warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #d32f2f;
  color: white;
  font-size: 14px;
  padding: 8px 16px 8px 16px;
}

.required-text {
  display: none;
  padding-right: 10px;
}

.mobile-options {
  cursor: pointer;
  display: none;
}

.wide-screen-options {
  cursor: pointer;
}

.inner-header-mobile {
  display: none;
}

.custom-labels-container {
  .MuiPaper-root {
    background-color: white;
  }

  .custom-labels-wrapper {
    padding: 20px;
  }

  .modal-labels-header {
    display: flex;
    justify-content: space-between;

    h3 {
      margin: 0px;
    }

    p {
      margin-top: 0px;
      color: rgba(0, 0, 0, 0.6);
    }

    button {
      color: #1C7BC0;
      border: 1px solid rgba(0, 0, 0, 0.38);
      padding: 2px;
    }
  }

  .modal-body {
    .table-header {
      padding: 15px 0px;
      padding-left: 15px;
      background-color: #f9f6f6;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    .labels-row {
      display: flex;
      align-items: self-start;
      justify-content: space-between;
      margin-bottom: 10px;

      .mobile-delete-label {
        display: none;
      }
    }

    .row-border {
      display: none;
    }
  }

  .add-row-btn {
    width: 100%;
    background: none;
    border: 1px dotted;
    cursor: pointer;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 8px;
    color: #1e74b6;
  }

  &.dark {
    .MuiPaper-root {
      background-color: #121212;
    }

    .add-row-btn {
      color: #39A6FF;
    }

    .modal-labels-header {
      button {
        color: white;
        border-color: white;
      }

      p {
        color: white;
      }
    }

    .modal-body {
      .table-header {
        background-color: #616161;
        color: white;
      }
    }
  }
}

.unavailable-banner {
  background: rgba(2, 136, 209, 0.1);
  display: flex;
  align-items: center;
  padding: 20px;

  p {
    margin: 0px;
    margin-left: 10px;
    font-weight: bold;
  }
}

.custom-fields-table {
  .expand-toggle {
    display: flex;
    align-items: center;

    svg {
      border: 2px solid white;
      border-radius: 2px;
      width: 0.9em;
      height: 0.9em;
      margin-right: 5px;
    }
  }

  .sub-custom-field-table {
    margin-left: 5%;
    @include sub-custom-field-values-table();
  }
}

.custom-dragdrop-header {
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;

  &.drag-header {
    padding-left: 50px;
  }
}

.create-dragdrop-buttons {
  margin-top: 20px;
  margin-right: 20px;
}

.create-custom-field-container {
  h2 {
    margin-top: 0px;
  }

  .card-container {
    // padding: 10px 20px 20px;
    margin: 20px;
    margin-left: 0px;
    border-radius: 3px;
  }

  .category-shadow {
    padding: 10px 20px 20px;
    box-shadow: 0px 0px 10px 3px #0000001f;
  }

  .fields-shadow {
    padding: 10px 20px 20px;
    box-shadow: 0px 0px 10px 3px #0000001f;
  }

  .custom-field-wrapper {
    min-height: 500px;

    h2 {
      margin-bottom: 10px;
    }

    .drag-text {
      margin-top: 0px;
    }
  }

  .add-custom-field {
    width: 100%;
    background: none;
    border: 1px dotted;
    cursor: pointer;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
    padding: 15px 8px;
    color: #39A6FF;
  }

  .custom-field-type-values-table {
    @include sub-custom-field-values-table();
    width: 100%;
  }
}

.single-customfield-div {
  border: 1px dotted #1C7BC0;
  padding: 10px 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  padding-top: 15px;
}

.category-mini-header {
  font-weight: bold;
  margin-bottom: 0px;
}

.custom-fields-back-arrow {
  display: flex;
  cursor: pointer;
  color: #39A6FF;
  margin-left: -10px;
  text-decoration: none;
}

.light-theme {
  .custom-fields-back-arrow {
    color: #1C7BC0;
  }

  .custom-fields-table {
    thead {
      th {
        color: rgba(0, 0, 0, 0.6);
        border-top: 0px;
      }
    }

    .expand-toggle {
      svg {
        border: 2px solid rgba(0, 0, 0, 0.38);
      }
    }

    .MuiTableRow-root.active {
      background: #f7f7f7;
    }

    .sub-custom-field-table {
      tbody {
        tr:nth-child(odd) {
          background-color: lighten(#1C7BC0, 50%);
        }
      }
    }

    .custom-dragdrop-header {
      background-color: #f9f6f6;
    }
  }

  .custom-field-type-values-table {
    tbody {
      tr:nth-child(odd) {
        background-color: lighten(#1C7BC0, 50%);
      }
    }
  }

  .add-custom-field {
    color: #1e74b6;
  }
}

@media screen and (max-width: 800px) {
  .custom-fields-table {
    .MuiTableCell-root {
      padding: 2px;
    }
  }

  .create-custom-field-container {
    .fields-shadow {
      box-shadow: none;
      padding: 10px 0px 20px;
    }
  }

  .custom-field-wrapper {
    .drag-text {
      display: none;
    }

    .custom-dragdrop-header {
      display: none;
    }

    .single-div-shadow {
      box-shadow: 0px 0px 10px 3px #0000001f;
    }

    .single-customfield-div {
      flex-wrap: wrap;
      border: none;
      padding: 15px 10px 10px 10px;

      > * {
        width: 100%;
        margin-top: 5px;
      }

      svg {
        width: 1em;
      }
    }
  }

  .required-text {
    display: block;
  }

  .mobile-options {
    display: block;
    transform: rotate(90deg);
    margin-left: 90%;
    background-color: #0000008a;
    border-radius: 2px;

    path {
      color: white;
    }
  }

  .wide-screen-options {
    display: none;
  }

  .element.style {
    display: none;
  }

  .inner-header-mobile {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }

  .custom-labels-container {
    .modal-body {
      margin-top: 10px;

      .table-header {
        display: none;
      }

      .labels-icon {
        display: none;
      }

      .labels-row {
        flex-direction: column;
        border-top-style: solid;
        border-color: #d3d2d299;

        .wide-screen-delete-label {
          display: none;
        }

        .mobile-delete-label {
          display: block;
          color: #1C7BC0;
          text-decoration: underline;
          padding: 0px 0px 0px 5px;
        }
      }

      .labels-input {
        padding: 10px;
        width: 100%;
        padding-top: 24px;

        .MuiInputLabel-root {
          left: 10px;
          top: 24px;
        }
      }

      .labels-input-value {
        padding: 3px 10px 3px 10px;
        width: 100%;

        .MuiInputLabel-root {
          left: 10px;
          top: 5px;
        }
      }
    }

    .add-row-border {
      border-top-style: solid;
      border-color: #d3d2d299;
    }
  }

  .desktop-empty-cells {
    display: none;
  }
}
