@use "../../assets/colors.module.scss" as colors;
.developer-body {
  h1 {
    font-size: 28px;
    font-weight: 700;
    color: #26282d;
  }
  .header-content {
    padding: 0px;
    padding-bottom: 16px;
    p {
      font-size: 16px;
    }
  }
  .body-content {
    padding-left: 0px;
  }
  .developer-header-div {
    background-color: #e4f2f8;
    padding: 16px;
  }

  .no-developer-container {
    height: 100%;
    align-content: center;
    justify-content: center;
    background-color: #f5f5f5;
  }

  .list-card-container {
    background-color: #f5f5f5;
    padding: 16px;

    .create-app-grid {
      .MuiGrid-item {
        padding-top: 20px;
      }
    }

    .MuiPaper-root {
      box-shadow: none;
      padding: 16px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    .MuiCardHeader-root {
      padding: 0px;
      margin: 0px 0px 20px 0px;
      background-color: transparent;
      .MuiCardHeader-content {
        display: flex;
        justify-content: space-between;
      }
      .MuiTypography-root {
        color: black;
        font-size: 16px;
        font-weight: 700;
      }
      .MuiCardHeader-subheader {
        font-weight: 400;
        padding-right: 10px;
      }
      .create-webhook-title {
        font-size: 18px;
        font-weight: 500;
      }
      .webhook-url {
        font-size: 16px;
        font-weight: 400;
        margin-right: 10px;
      }
      .MuiCardHeader-action {
        margin: 0px;
      }
    }

    .MuiCardContent-root {
      padding: 0px;
    }

    .MuiFormHelperText-root {
      margin-left: 0px;
    }
    .maximum-type-error {
      color: #d32f2f;
      font-size: 12px;
    }
    .expired-secret {
      color: #ff4d4f;
    }

    .application-detail-bold {
      font-weight: 500;
      size: 20px;
    }
    .add-secret-button {
      color: colors.$blue300;
      font-weight: 600;
      padding: 0px;
      margin: 16px;
      &.disabled {
        color: #c4c4c4;
      }
    }

    .clear-button {
      color: colors.$blue300;
    }

    .create-button {
      background-color: colors.$blue300;
      &.disabled {
        background-color: #d9d9d9;
      }
    }

    .application-edit-create {
      background-color: transparent;
      padding: 0px;
      margin-bottom: 0px;
      .create-edit-divs {
        background-color: white;
        border-radius: 4px;
        padding: 16px;
        font-size: 14px;
        border: 1px solid #d9d9d9;
        .MuiGrid-item {
          padding-top: 0px;
        }
        .MuiTableCell-head {
          font-weight: 500;
        }
        .MuiTableCell-root.MuiTableCell-head,
        .MuiTableRow-root:hover {
          background-color: initial;
        }
        .MuiTableCell-root {
          border-bottom-width: 0px;
          max-width: 250px;
        }
        .MuiTableCell-body {
          padding-top: 0px;
        }
        .Mui-error .MuiOutlinedInput-notchedOutline {
          border-color: #ff4d4f;
        }
        .MuiFormHelperText-root.Mui-error {
          color: #ff4d4f;
        }
      }
    }
    .delete-button {
      color: #ff4d4f;
      border-color: #ff4d4f;
      background-color: white;
      font-weight: 700;
      padding: 6.5px 16px;
      font-size: 16px;
      &:hover {
        background-color: rgba(11, 48, 76, 0.04);
      }
    }
  }
}

.secret-modal {
  .MuiBox-root {
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 619px;
    height: 375px;
    border-radius: 10px;
    padding: 24px;
    p {
      margin: 0px;
      margin-bottom: 24px;
    }
    .copy-secret-div {
      align-items: center;
      width: 500px;
      margin: auto;
      border-style: solid;
      border-color: #d9d9d9;
      border-radius: 5px;
      margin-bottom: 24px;
    }
    .recorded-secret-button {
      width: 398px;
      height: 32px;
      background-color: #1C7BC0;
      color: white;
      align-items: center;
    }
    .copy-button {
      text-align: center;
      background-color: #fafafa;
    }
  }
  &.dark {
    .MuiBox-root {
      background-color: #2a2d35;
      .copy-button {
        background-color: transparent;
      }
    }
  }
}

.primary-medium-button {
  padding: 6.5px 16px;
  background-color: colors.$blue300;
  font-size: 16px;
  &:hover {
    background-color: colors.$blue400;
  }
}

.secondary-medium-button {
  padding: 6.5px 16px;
  color: colors.$blue300;
  border: solid 1px colors.$blue300;
  background-color: transparent;
  font-size: 16px;
  &:hover {
    border: solid 1px colors.$blue300;
    background-color: rgba(11, 48, 76, 0.04);
  }
}

.dark-theme {
  .developer-body {
    h1 {
      color: white;
    }
    .paper-section {
      .developer-header-div {
        background-color: #2a2d35;
      }
      .body-content {
        background-color: #323741;
        .list-card-container {
          background-color: #323741;
          .delete-button {
            background-color: transparent;
          }
          .MuiCardHeader-root {
            .MuiTypography-root {
              color: white;
            }
          }
          .application-edit-create {
            .create-edit-divs {
              background-color: #2a2d35;

              //   .Mui-error .MuiOutlinedInput-notchedOutline {
              //     border-color: #ff4d4f;
              //   }
              //   .MuiFormHelperText-root.Mui-error {
              //     color: #ff4d4f;
              //   }
            }
          }
          .create-button {
            &.disabled {
              color: rgba(255, 255, 255, 0.3);
              box-shadow: none;
              background-color: rgba(255, 255, 255, 0.12);
            }
          }
        }
      }
    }
    .no-developer-container {
      background-color: transparent;
    }
  }
  .secondary-medium-button {
    // .MuiButton-root:hover {
    background-color: transparent;
    border: 1px solid colors.$blue300;
    // }
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #ff4d4f;
  }
  .MuiFormHelperText-root.Mui-error {
    color: #ff4d4f;
  }
  .back-arrow:hover {
    background-color: transparent;
  }
}
