@import "./_colors.scss";
@import "./_variables.scss";

:root {
  /** Branding **/
  --primary-blue: #{color(blue, base)};

  /*** Typography ***/
  --primary-typography: #2a2d35;

  /*** data-display variables ***/
  --success-color: #{color(success, 400)};
  --success-border: #{color(success, 300)};
  --success-bck-color: #{color(success, 100)};
  --failed-color: #{color(error, 400)};
  --failed-border: #{color(error, 300)};
  --failed-bck-color: #{color(error, 100)};
  --warning-color: #{color(orange, 500)};
  --warning-border: #{color(orange, 400)};
  --warning-bck-color: #{color(yellow, 100)};

  /*** layout variables ***/
  --container: #{color(neutrals, 400)};
  --card: #{color(neutrals, white)};
  --create-header: #{color(blue, 100)};
  --table-header: #{color(blue, 100)};
  --table-body: #{color(neutrals, white)};
  --menu-background: #{color(blue, 100)};

  /*** button vairables ***/
  --primary-hovered-background: #{color(blue, 475)};
  --primary-disabled-background: #{color(neutrals, 400)};
  --secondary-background: #{color(neutrals, white)};
  --secondary-border: #{color(blue, base)};
  --secondary-hovered-border: #{color(blue, 475)};
  --link-hovered-background: #{color(neutrals, 200)};
  --link-disabled: #{color(neutrals, 300)};
}

.dark-theme {
  /*** Branding ***/
  --primary-blue: #{color(blue, 375)};

  /*** Typography ***/
  --primary-typography: white;

  /*** data-display variables ***/
  --success-border: #{color(success, 500)};
  --success-bck-color: #{color(success, 600)};
  --failed-border: #{color(error, 500)};
  --failed-bck-color: #{color(error, 600)};
  --warning-border: #{color(yellow, 500)};
  --warning-bck-color: #{color(yellow, 600)};
  --warning-color: #{color(yellow, 100)};

  /*** layout variables ***/
  --container: #{color(neutrals, 600)};
  --card: #{color(neutrals, 700)};
  --create-header: transparent;
  --table-header: #{color(neutrals, 700)};
  --table-body: transparent;
  --menu-background: #{color(blue, 500)};

  /*** button vairables ***/
  --primary-hovered-background: #{color(blue, 460)};
  --primary-disabled-background: #{color(neutrals, 800)};
  --secondary-border: #{color(blue, 375)};
  --secondary-hovered-border: #{color(blue, 460)};
  --secondary-background: "inherit";
  --link-hovered-background: #{color(neutrals, 700)};
  --link-disabled: #{color(neutrals, 500)};
}
