@import "../../../../assets/v2/_colors.scss";
@import "../../../../assets/v2/variables.scss";
.card-payment {
  width: 600px;
  @include breakpoint("medium") {
    width: 100%;
  }
  h2.h4 {
    color: color(neutrals, 600);
    margin-bottom: spacer(3);
    font-weight: 600;
  }
  .transaction-amount {
    margin-bottom: spacer(5);
    font-size: 32px;
  }
  .btn {
    width: 100%;
  }
  .btn--link {
    margin-left: -5px;
    margin-bottom: spacer(4);
  }
  .payment-adjustment-btn {
    background: none;
    border: none;
  }
  .totals-section {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: spacer(4);
    margin-bottom: spacer(4);
    margin-top: spacer(4);
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .hide-address {
    display: none;
  }
  .MuiGrid-container {
    .MuiGrid-root {
      // max-width: fit-content;
      max-width: 50% !important;
      flex-basis: 50%;
      text-align: start;
    }
    .MuiAutocomplete-inputRoot {
      padding: 0px;
    }
    .MuiAutocomplete-root {
      label {
        padding-top: 10px;
      }
    }
  }

  .payment-details {
    .MuiFormControl-root {
      max-width: 100%;
    }
    .combination-inputs {
      .MuiButtonBase-root {
        padding-right: 0px;
      }
    }
  }
  .tokenizer-hidden {
    display: none;
    // height: 0px;
  }
  .customer-name {
    font-size: 1rem;
    color: color(blue, 400);
    margin-bottom: spacer(2);
  }
  .address-wrapper {
    .MuiTextField-root {
      margin-top: spacer(1);
    }

    [data-cy="contact-country"],
    [data-cy="contact-state"] {
      margin-top: -4px;
    }
  }
}

.dark-theme {
  .card-payment {
    .customer-name {
      color: white;
    }
    .combination-inputs > div:first-of-type .MuiOutlinedInput-root {
      background: color(neutrals, 600);
      .MuiSelect-select {
        -webkit-text-fill-color: white;
      }
    }
    h2.h4 {
      color: color(neutrals, white);
    }
  }
}
