@import "../../../assets/v2/_colors.scss";
@import "../../../assets/v2/_variables.scss";
.customer-pagination-vt {
  .pagination-results-dropdown {
    display: none;
  }
}

.old-vt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// this is being deleted once we move over to the new vt
.preview-banner.tag {
  padding: 6.5px spacer(2);
  font-size: spacer(3);
  border-radius: spacer(1);
  &__blue {
    color: color(blue, base);
    border: 1px solid color(blue, 250);
    background-color: color(blue, 100);
  }

  &.important {
    font-weight: 600;
  }
}

.vt-tokenizer {
  margin-top: -5px;
  .tokenEx-card-wrapper {
    .card {
      width: 100%;
    }
    .secondary {
      padding-top: 5px;
      width: 50%;
      .cvv,
      .exp {
        flex: 1 1 50%;
        min-width: 50px;
        max-width: initial;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .vt-payment-options {
    .MuiButtonBase-root.MuiTab-root:last-of-type {
      display: none;
    }
  }
}
