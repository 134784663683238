@import "../../../assets/v2/_colors.scss";
@import "../../../assets/v2/variables.scss";
.terminal-wrapper {
  .error--text {
    color: #cf6679;
    font-weight: bold;
  }
  .terminal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: spacer(5);
  }

  h2.h4 {
    color: color(neutrals, 600);
    margin-bottom: spacer(3);
    font-weight: 600;
  }

  .link--btn {
    margin-left: -5px;
    margin-bottom: spacer(4);
  }

  .card-container:first-of-type {
    margin-bottom: 16px;
  }
  .input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacer(5);
    > div {
      width: calc((100% / 2) - spacer(3));
    }
  }
  .tax-section {
    margin-top: spacer(3);
  }

  .price-breakdown {
    margin-bottom: spacer(2);
    display: flex;
    justify-content: space-between;
    color: color(neutrals, 600);
    &.subtotal {
      border-top: 1px solid color(neutrals, 300);
      padding-top: spacer(3);
      font-weight: 600;
    }
  }

  .pricing-section {
    margin-top: spacer(4);
  }
  .price-summary {
    background-color: color(blue, 100);
    border-radius: 4px;
    padding: spacer(2);
  }
  .additional-fields {
    width: 49%;
    padding-top: spacer(5);
    @include breakpoint("medium") {
      width: 100%;
    }

    &.hide {
      display: none;
    }
  }
  .customer-section {
    margin-top: spacer(5);
    .btn--secondary {
      width: 100%;
      > div {
        display: flex;
        justify-content: center;
        align-items: end;
        font-size: 18px;
        svg {
          margin-right: spacer(2);
        }
      }
    }
  }
  .customer-name {
    display: flex;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 18px;
    }
    button {
      padding-bottom: spacer(2);
    }
    .delete-btn {
      border-radius: 4px;
      border: 1px solid color(neutrals, 300);
      padding: 6px;
      margin-left: spacer(7);
      svg {
        font-size: 18px;
        fill {
          color: black;
        }
      }
    }
  }
}
.combination-inputs {
  display: flex;
  align-items: end;
  > div:first-of-type {
    width: 82px;
    min-width: fit-content;
    .MuiOutlinedInput-root {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background: color(neutrals, 400);
    }
    .MuiSelect-select {
      padding-left: spacer(2);
      -webkit-text-fill-color: black;
    }
  }
  > div:last-of-type {
    .MuiOutlinedInput-root {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: spacer(2);
      input {
        text-align: left;
        padding: 0px;
      }
    }
  }
}

.terminal-option {
  background: inherit;
  display: flex;
  padding: 2px spacer(3);
  width: 100%;
  border: 1px solid color(neutrals, 300);
  border-radius: spacer(1);
  align-items: center;
  cursor: pointer;
  margin-bottom: spacer(3);
  &:disabled {
    cursor: not-allowed;
  }
  span {
    padding: spacer(1) spacer(1);
    border-radius: spacer(1);
    margin-right: spacer(4);
    svg {
      font-size: 36px;
    }
  }
  > div {
    text-align: left;
    p {
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.payment-selection-modal {
  .MuiPaper-root {
    padding: spacer(7);
    scrollbar-width: none;
  }
  &.true {
    .MuiPaper-root {
      width: 100%;
    }
  }
  .payment-method-row {
    padding-left: spacer(6);
    .header {
      font-weight: 500;
      @include breakpoint("small") {
        display: none;
      }
    }
    > div {
      display: flex;
    }
    span {
      width: calc(100% / 3);
    }
    svg {
      margin-right: spacer(2);
    }
    @include breakpoint("small") {
      padding-left: 0px;
      span {
        width: 100%;
        &:last-of-type {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints,"small")) {
  .terminal-wrapper {
    .input-wrapper {
      flex-direction: column;
      margin-bottom: 0px;
      > div {
        width: 100%;
        margin-bottom: spacer(5);
      }
    }
    .tax-section {
      margin-top: 0px;
    }
  }
}

.payment-success-modal {
  display: flex;
  justify-content: center;
  padding: 60px 0px;
  width: 600px;
  @include breakpoint("small") {
    padding: 0px;
    width: 100%;
  }

  .transaction-amount {
    margin-bottom: spacer(5);
    font-size: 32px;
  }
  h2 {
    align-self: center;
    font-size: 30px;
  }
  p {
    text-align: center;
  }

  img {
    max-width: 200px;
    align-self: center;
  }
  > div {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    @include breakpoint("small") {
      min-width: initial;
    }
  }
  button {
    margin-bottom: spacer(2);
    &:first-of-type {
      margin-top: spacer(10);
    }
  }
  .email-receipt-success {
    display: flex;
    margin-top: spacer(8);
    button.sm {
      margin: 0px;
      display: flex;
      padding: 2px 16px;
      max-width: fit-content;
      min-width: fit-content;
      margin-left: spacer(3);
      height: max-content;
      margin-top: 14px;
    }
    svg {
      font-size: 18px;
      margin-left: 5px;
    }
  }
}

.dark-theme {
  .terminal-wrapper {
    .combination-inputs > div:first-of-type .MuiOutlinedInput-root {
      background: color(neutrals, 600);
      .MuiSelect-select {
        -webkit-text-fill-color: white;
      }
    }
    .price-summary {
      span {
        color: white;
      }
    }

    .terminal-option {
      p {
        color: white;
      }
      span svg {
        fill: white;
      }
      &:disabled {
        p {
          color: color(neutrals, 500);
        }
        span svg {
          fill: color(neutrals, 500);
        }
      }
    }
    h2.h4 {
      color: white;
    }
  }

  &.payment-selection-modal {
    .MuiPaper-root {
      background-image: none;
      background-color: color(neutrals, 700);
    }
  }
}
