@mixin setting-div {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-between;
}

.gateway-badge {
  width: padding;
  padding: 4px 8px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  display: inline-block;
  text-transform: lowercase;
  font-weight: 600;
  &.active {
    background: #54bee1;
  }
  &.disabled {
    background: red;
  }
  &.pending_tos {
    color: #faad14;
    background-color: #fffbe6;
  }
  &.InMigrationQueue,
  &.migrating,
  &.migrationFailed {
    background: #ed6c02;
  }
}

.gateway-info {
  padding: 16px 0px 0px 16px;
  .gateway-phone {
    width: 27%;
    margin-right: 5px;
  }
  .MuiFormControl-root {
    margin-top: 10px;
    margin-right: 5px;
    // background-color: red;
  }
  .MuiOutlinedInput-root {
    // margin:5px;
  }
}

.gateway-settings {
  .accordian-text {
    font-size: 14px;
    color: black;
  }
  .expand-all-button {
    font-size: 24px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    color: rgb(24, 154, 208);
  }
  .divider {
    height: 50%;
    width: 100%;
    border-bottom: 1px solid black;
  }

  .save-cancel-container {
    button {
      padding: 0px;
    }
  }

  .single-col-setting-div {
    width: 50%;
  }

  p:not(.expand-close-text, .accordian-text) {
    font-size: 12px;
  }
  .gateway-settings-descrp {
    font-size: 12px;
  }
  .gateway-switch {
    .MuiFormControlLabel-root {
      margin: 0px;
    }
  }

  .MuiAccordionSummary-root {
    background-color: transparent;
    padding: 10px 0px 10px 0px;
    .MuiTypography-root {
      font-weight: 800;
    }
    svg {
      color: black;
    }
  }
  .Mui-disabled:not(.MuiSwitch-switchBase) {
    padding: 0px;
  }

  .MuiPaper-root {
    box-shadow: none;
  }
  #edit-setting {
    padding: 0px;
  }
}

.gateway-address-container {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .MuiPaper-root {
    box-shadow: 4px 5px 5px 3px rgb(0 0 0 / 20%);
  }
  .MuiButton-root {
    min-width: fit-content;
    justify-content: flex-start;
    padding: 0px;
    color: rgb(24, 154, 208);
    font-weight: 800;
    &:hover {
      background-color: transparent;
    }
  }

  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
  }
}

.address-header {
  font-size: 20px;
  font-weight: 700;
}

.time-fee-container {
  display: flex;
  justify-content: space-between;
  .MuiFormControl-root {
    width: 100%;
  }
}
.retry-migration-block {
  margin-top: 10px;
  p {
    margin: 0px;
  }
}

.migration-scheduler-container {
  h2 {
    display: block;
    text-align: center;
  }
  .migration-scheduler__body {
    padding: 10px 0px 20px;
    display: flex;
    flex-direction: column;
    .schedule-migration-button-group {
      display: flex;
      margin-top: 40px;
      margin: 20px 80px 20px 80px;
    }
    .MuiFormControl-root {
      max-width: 200px;
    }
    .migration--label__hint {
      font-size: 14px;
      margin-bottom: 0px;
    }
  }
  .scheduler-banner {
    display: flex;
    align-items: center;
    background: #fdf4e7;
    padding: 0px 20px;
    .MuiSvgIcon-root {
      color: #e08040;
      margin-right: 15px;
    }
    &.dark {
      color: #000;
    }
  }
  .error--text {
    color: #cf6679;
    font-weight: bold;
    padding: 0 2em;
  }
}

.dark-theme {
  .backbutton-color {
    color: #39A6FF;
  }
  .leave-gateway-btn {
    color: #39A6FF;
  }
  .line-progress-container {
    .MuiLinearProgress-root {
      background-color: white;
    }
    .MuiLinearProgress-bar {
      background-color: #39A6FF;
    }
  }
  .gateway-settings {
    .accordian-text {
      color: white;
    }
    .divider {
      border-bottom: 1px solid white;
    }
    .MuiAccordionSummary-root {
      svg {
        color: white;
      }
    }
  }
  .gateway-badge {
    &.pending_tos {
      background-color: #461d02;
    }
  }
}

.gateway-list {
  #save-favorites {
    display: none;
  }
}

.gateway-details-container {
  h2 {
    margin: 0px;
  }
  .gateway-status {
    color: #1C7BC0;
    &.disabled {
      color: red;
    }
    &.disabled {
      color: red;
    }
    &.pending_tos {
      color: #fbc02d;
    }
  }
}

.gateway-link-wrapper {
  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  .MuiPagination-root {
    margin-bottom: 15px;
  }
  #save-favorites {
    display: none;
  }
}

.setting-toggles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .MuiFormControlLabel-root {
    margin-left: 0px;
    width: 40%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 800px) {
  .setting-toggles {
    .MuiFormControlLabel-root {
      width: 100%;
    }
  }
  .gateway-info-submit {
    button {
      width: 100%;
      margin-right: 10px;
      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
  .migration-scheduler-container {
    .migration-scheduler__body {
      .schedule-migration-button-group {
        display: block;
        margin: auto;
        button {
          margin-top: 12px;
        }
      }
    }
  }
  .gateway-settings {
    .single-col-setting-div {
      width: 100%;
    }
  }
}
@media screen and (min-width: 801px) and (max-width: 1024px) {
  .setting-toggles {
    .MuiFormControlLabel-root {
      width: 100%;
    }
  }
}
