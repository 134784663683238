.MuiAlert-filledWarning {
  color: #000000de;
}

.banner-alert {
  .MuiDrawer-root {
    > div {
      margin-top: 52px;
    }
  }
  header {
    margin-top: 52px;
  }
  .MuiSnackbar-root {
    width: 100%;
    top: 0px;
    > div {
      border-radius: 0px;
      width: inherit;
    }
  }
}

@media screen and (min-width: 476px) {
  .alert-wrapper {
    .MuiDialog-container {
      > .MuiDialog-paper {
        min-width: 315px;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .banner-alert {
    .MuiDrawer-root {
      > div {
        margin-top: 52px;
      }
    }
    header {
      margin-top: 52px;
    }
  }
}
