@import "./typography";
/* ===============
   Layout
   =============== */

.iqPro-page-container {
  display: flex;
  justify-content: center;
  padding: 32px 80px;
  @include breakpoint("medium") {
    padding: 60px 40px;
  }
  .sub-wrapper {
    width: 100%;
    max-width: 1400px;
  }
}

.card-container {
  padding: spacer(4);
  background: var(--container);
}
.card-obj {
  padding: spacer(4);
  background-color: var(--card);
  height: 100%;
}

.sub-card-container {
  border: solid color(neutrals, 300);
  border-radius: spacer(1);
  background-color: var(--card);
  padding: spacer(4);
  &.borderless {
    border: none;
  }
}

.blue-card {
  background: color(neutrals, 600);
}

.create-div {
  padding: spacer(2);
  background-color: var(--create-header);
  border-radius: 5px;
}

.menu {
  padding: 4px;
  .MuiMenuItem-root {
    @extend .h6;
    &:hover {
      background-color: var(--menu-background);
      @extend .sub-2;
    }
  }
}

@at-root .dark-theme {
  .blue-card {
    background: color(blue, 600);
  }
}
