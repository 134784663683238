.tokenEx-card-wrapper {
  padding: 15px 0px;
  padding-top: 0px;
  margin-left: -5px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: hidden;
  font-family:
    Open Sans,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  .card-row {
    color: #666;
    font-weight: 400;
    display: block;
    min-width: 200px;
    height: 30px;
    padding-left: 5px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    background-color: #fff;
    outline: 0;
    box-sizing: border-box;
    margin-left: 5px;
    &.exp {
      min-width: 50px;
      max-width: 100px;
      padding: 5px 10px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgb(186, 187, 189);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgb(186, 187, 189);
      }
    }
    &.cvv {
      min-width: 50px;
      max-width: 100px;
    }
  }
  .card-wrapper {
    display: flex;
    align-items: center;
  }
  #iframe-container {
    margin-left: 5px;
    height: 30px;
    width: 100%;
  }
  .form-label {
    color: #1b1b1b;
    font-size: 14px;
    margin-left: 5px;
    display: block;
    margin-bottom: 5px;
  }

  .invalid {
    .card-row {
      border-color: red;
    }
  }
}

.dark-theme {
  .tokenEx-card-wrapper {
    .form-label {
      color: white;
    }
    .card-row {
      background: inherit;
      border-color: #666;
      color: white;
    }
    .MuiSvgIcon-colorSecondary {
      color: #39A6FF;
    }
    .invalid {
      .card-row {
        border-color: #ef5a00;
      }
    }
  }
}
