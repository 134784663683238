.drag-list-item {
  border: 1px dotted #1C7BC0;
  padding: 10px 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  padding-top: 15px;
  flex-direction: column;
}
.handle-block {
  display: flex;
  align-items: center;
  svg {
    margin-right: 15px;
    cursor: pointer;
  }
}

.multi-custom-field-div {
  display: inherit;
  width: 100%;
  justify-content: inherit;
}

@media screen and (max-width: 800px) {
  .drag-list-item {
    border: none;
    box-shadow: 0px 0px 10px 3px #0000001f;
    padding: 15px 10px 10px 10px;
    margin-bottom: 10px;
  }
  .multi-custom-field-div {
    flex-wrap: wrap;

    > * {
      width: 100%;
      margin-top: 5px;
    }

    svg {
      width: 1em;
    }
  }
  .handle-block {
    display: none;
  }
}
