.error-container {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px;

  p {
    font-weight: bold;
  }
  .error-information {
    margin-top: 60px;

    p {
      margin: 0px;
    }
  }
  .no-processors {
    > h1 {
      color: var(--primary-blue);
    }
    > img {
      height: 300px;
    }
  }
}
