.transactions-wrapper {
  min-width: max-content;

  .MuiTableContainer-root {
    overflow-x: initial;
  }

  th {
    top: 40px;
  }

  .download-button {
    background-color: white;
    flex-grow: 1;
    text-align: right;
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: sticky;
    z-index: 1001;
    top: 0px;
  }
}

.dark-theme {
  .transactions-wrapper {
    .download-button {
      background-color: #121212;
    }
  }
}

.transaction-pdf-table {
  position: absolute;
  left: -9999px;
  top: -9999px;
  padding: 120px 50px 0px 50px;
  width: 1100px;
  height: auto;

  .MuiChip-root {
    display: inherit;
  }

  .header-row {
    margin-bottom: 120px;

    div {
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: center;

      img {
        width: 180px;
        margin-bottom: 25px;
      }
    }

    h2 {
      text-align: center;
      display: block;
      margin: 0;
      font-weight: 550;
      font-size: 34px;
      color: #01579b;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
  }

  .sortable-header .MuiSvgIcon-root {
    display: none;
  }

  table,
  td,
  th,
  tfoot td {
    position: static;
    color: black;
    font-size: 24px;
    border-color: darkgray;
  }

  .MuiTableCell-root.MuiTableCell-head {
    background-color: white;
  }

  th {
    position: initial;
  }

  tbody tr:nth-child(17) {
    td {
      padding-bottom: 100px;
      border-bottom: none;
    }
  }

  tbody tr:nth-child(21n + 17) {
    td {
      padding-bottom: 100px;
      border-bottom: none;
    }
  }

  .force-break {
    display: block;
  }
}

.MuiDialog-root .invoice-tabs {
  margin: 0;
  box-shadow: none;
  padding: 0;

  .MuiFormControl-root {
    max-width: 100%;
  }

  .MuiGrid-root {
    text-align: left;
  }
}
