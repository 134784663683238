.status-badge {
  padding: 4px 8px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  display: inline-block;
  text-transform: lowercase;
  font-weight: 600;
  &.Active {
    background: #54bee1;
  }
  &.Disabled {
    background: red;
  }
  &.InvitePending {
    background: #01579b;
  }
}

.user-list {
  #save-favorites {
    display: none;
  }
}
