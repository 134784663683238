@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./assets/fonts/Plus-Jakarta-Sans.ttf') format('truetype');
  font-weight: 200 800;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
