.IQAccordion-wrapper {
  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiAccordionSummary-root {
    background-color: transparent;
    padding: 0px;

    p {
      font-size: 16px;
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    color: #858585;
  }

  .MuiAccordionDetails-root {
    padding: 0px;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;

    &.Mui-expanded {
      margin-bottom: 10px;
    }
  }

  .title-border {
    height: 1px;
    width: 90%;
    background: #858585;
    margin-right: 15px;
  }

  .dark-theme {
    .title-border {
      background: white;
    }

    .MuiAccordionSummary-expandIconWrapper {
      color: white;
    }

    .MuiAccordionSummary-root {
      background-color: unset;
    }

    &.MuiPaper-root {
      background-color: #121212;
    }
  }
}

.accordion-group-container {
  .toggle-row {
    display: flex;
    flex-direction: row-reverse;
  }

  .toggle-button {
    button {
      background: none;
      border: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #858585;

      span {
        color: #000000;
      }
    }

    &.dark-theme {
      color: white;

      span {
        color: white;
      }
    }
  }
}
