.processor-form {
  .header-row {
    display: flex;
  }
  .form-row {
    margin-bottom: 15px;
  }

  .radio-btn-section {
    .MuiFormControl-root {
      padding-right: 20px;
    }
  }

  .otc-form {
    .otc-form-wrapper {
      display: flex;
    }

    .otc-text-inputs {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .MuiFormControl-root {
        flex-basis: 45%;
        padding-right: 10px;
      }
    }

    .fiserv-text-inputs {
      display: flex;
      flex-wrap: wrap;
      .MuiFormControl-root {
        flex-basis: 45%;
        padding-right: 10px;
        padding-bottom: 20px;
      }
    }

    .split-section {
      display: flex;
      align-items: center;
      .MuiFormControl-root {
        margin-bottom: 10px;
        margin-left: 10px;
      }
    }
  }
  .advanced-form-section {
    padding-bottom: 20px;
  }

  .sec-info-block {
    background: lightgrey;
    display: flex;
    padding: 15px;
    background: rgba(2, 136, 209, 0.1);
  }
}
