@use "../../../assets/colors.module.scss" as colors;

.required-fields-wrapper {
  overflow-x: initial;
}

.payables-templates {
  padding-bottom: 40px;
  .MuiTextField-root {
    .MuiOutlinedInput-root {
      height: 50px;
    }
  }

  .template-block {
    width: 50%;
    margin: 40px 0px;
  }
  .color-picker-row {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
  }
  .color-picker-output {
    display: flex;
  }
  .header-block {
    background: colors.$lightblue500;
    padding: 5px 15px;
    border-radius: 3px;
    h2 {
      margin: 0px;
    }
  }
  .color-picker-output {
    input {
      width: 90px;
    }
  }
  .color-picker-preview {
    background: transparent;
    border: 1px solid #eaebeb;
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
    margin-right: 5px;
    div {
      width: 25px;
      height: 25px;
    }
  }
  .color-picker-title span {
    font-weight: 500;
  }
  .btn-row {
    margin: 40px 0px;
    button {
      width: initial;
      padding: 10px 35px;
    }
  }
}

.color-picker-modal {
  .MuiPaper-root {
    padding: 20px;
  }
  .react-colorful {
    height: 250px;
    .react-colorful__last-control {
      margin-top: 20px;
    }
  }
  .input-container {
    display: flex;
    justify-content: center;
  }
  input {
    margin-top: 20px;
  }
}
.light-theme {
  .payables-templates {
    p {
      color: #848383;
      span {
        font-weight: 600;
      }
    }
    .header-block {
      background: colors.$grey400;
    }
    .color-picker-title span {
      color: #616161;
    }
  }
}

@media screen and (max-width: 800px) {
  .required-fields-wrapper {
    .MuiTableCell-root {
      max-width: 85px;
    }
  }
  .payables-templates {
    .template-block {
      width: 100%;
    }
  }
}
