.dark-theme {
  container-name: dark-theme;
  container-type: inline-size;
}
.v2 {
  @import "./_colors.scss";
  @import "./_variables.scss";
  @import "./_data-entry.scss";
  @import "./_typography.scss";
  @import "./_layout.scss";
  @import "./_buttons.scss";
  @import "./_data-display.scss";
}

@import "./_theme.scss";
