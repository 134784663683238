@use "../../../../assets/colors.module.scss" as colors;

.customer-detail-container {
  padding-right: 8px;
  table {
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 5px;
    border-spacing: revert;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .content-container {
    all: initial;
    h1,
    h2:nth-child(2) {
      display: none;
    }
    .header-content {
      display: none;
    }
    .body-content {
      all: initial;
    }
    .footer-content {
      display: none;
    }
  }

  .transactions-message {
    color: #5c5b5b;
    font-weight: bold;
    font-size: 14px;
    margin-top: 30px;
  }
}

.screen-wrapper {
  position: relative;
  overflow-x: clip;
  > div {
    min-height: 300px;
    position: absolute;
    width: 100%;
    transition: all 250ms;
  }
  .panel-details {
    right: -9999px;
  }
  .button-group {
    margin-top: 30px;
  }
  .origin-panel {
    left: 0px;
  }
  &.new-panel {
    .panel-details {
      right: 0px;
    }
    .origin-panel {
      left: -9999px;
    }
  }
}

.customer-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .action-button {
    margin-right: 10px;
  }
}

h2 {
  display: flex;
  font-size: 20px;
  svg {
    fill: #fbc02d !important;
    margin-left: 5px;
    cursor: pointer;
  }
}
.header-content {
  border-bottom: 1px solid gainsboro !important;
}

.details-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .form-sm {
    width: 25%;
  }
  .form-md {
    width: 50%;
  }
}

.flags-section {
  margin-left: 15px;
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
  .MuiFormControlLabel-root {
    margin-left: 0px;
  }
}

.payment-cell {
  display: flex;
  svg {
    margin-right: 5px;
  }
  p {
    margin: 0px;
  }
}

.customer-details-wrapper {
  margin-bottom: 20px;
  padding-bottom: 40px;
  padding-right: 8px;
  border-bottom: 1px solid #858585;
}
.address-block {
  p {
    margin: 0px;
  }
}

.address-button-toggles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  .label-text {
    font-weight: bold;
    padding-top: 5px;
    span {
      font-size: 15px;
    }
    div {
      display: inline-block;
    }
  }
}
.address-button-group {
  margin-top: 30px;
}
.default-row {
  align-items: baseline;
  display: flex;
  margin-bottom: 20px;
}
.address-header {
  display: flex;
  align-items: center;
  h3 {
    margin-bottom: 15px;
  }
}
.MuiToggleButton-root.Mui-selected {
  background: colors.$blue300;
  color: white;
}
.contact-block {
  > div {
    display: flex;
    align-items: center;
  }
  p {
    margin: 0px;
    margin-left: 15px;
  }
}

.payment-body {
  .default-toggle {
    display: flex;
    align-items: center;
    .MuiTypography-root {
      margin-left: 10px;
    }
  }
  .tokenizer-form {
    width: 60%;
    margin-bottom: 25px;
  }
}

.back-arrow {
  display: flex;
  color: colors.$blue300;
  text-decoration: none;
  cursor: pointer;
  margin-left: -10px;
}
.dark-theme {
  .back-arrow {
    color: colors.$blue300;
  }
  .MuiToggleButton-root.Mui-selected {
    background: colors.$blue550;
    color: white;
  }
}
.view-only-field {
  display: flex;
  flex-direction: column;
  min-width: 25%;
  span {
    font-size: 12px;
  }
  p {
    margin: 0px;
  }
}

.dark-theme {
  .customer-table-icon {
    .MuiSvgIcon-root {
      color: #fff;
    }
  }

  .MuiIcon-root {
    svg {
      fill: currentColor;
    }
  }
}

.custom-fields-customer-info {
  width: 50%;
}

.payable-url-box {
  input {
    text-align: center;
    text-overflow: ellipsis;
  }
  .MuiOutlinedInput-root {
    height: 60px;
    background-color: rgb(0, 0, 0, 0.1);
  }
  .Mui-disabled {
    -webkit-text-fill-color: black;
  }
}

.dark-theme {
  .payable-url-box .Mui-disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (max-width: 1260px) {
  .address-button-toggles {
    .label-text.label-text__table {
      span {
        font-size: 13px;
      }
      div {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .custom-fields-customer-info {
    width: 100%;
  }
}
