$system_blue: #3c5978;
$system_white: #ffffff;
$system_dark: #1e1e1e;
$system_darkblue: #292d30;

// from theme.js
$blue200: #66f3ff;
$blue250: #8bcce8;
$blue300: #1C7BC0;
$blue400: #16598E;
$blue450: #099ae9;
$blue500: #01579b;
$blue550: #39A6FF;
$blue575: #1B84F5;
$blue600: #0b304c;
$blue800: #000624;

$lightblue200: #eafaff;
$lightblue250: #ebf5fe;
$lightblue300: #aee7ff;
$lightblue350: #B7E7FF;
$lightblue400: #76cbe7;
$lightblue500: #39A6FF;

// yellow
$yellow100: #fcbc34;
$yellow200: #f5c71a;
$yellow300: #f5c71a;
$yellow400: #f5c71a;

// neutrals
$grey_blue800: #404854;
$grey100: #d9d9d9;
$grey200: #FAFAFA;
$grey300: #cbcbd6;
$grey400: #dff1f8;
$grey450: #858585;
$grey500: #686868;
$grey600: #eaebeb;
$grey650: #E8E8ED;
$grey700: #616161;
$grey750: #2a2d35;
$grey775: #323741;
$grey800: #353536;
$grey825: #1e2229;
$grey850: #121212;
$grey900: #1b1b1b;

//purple
$purple200: #4552A3;
$purple400: #40488D;
// error
$error: #cf6679;

// warning dark
$warning_dark: #ed6c02;

// drawer
$drawer: #f4f5f5;

$tableHeader: #fafafa;

// header
$header_blue: #1a3640;

// footer
$footer_background: #eaeff1;

// login
$login_background: #3c5978;

:export {
  system-blue: $system_blue;
  system_white: $system_white;
  system_dark: $system_dark;

  blue200: $blue200;
  blue250: $blue250;
  blue300: $blue300;
  blue400: $blue400;
  blue450: $blue450;
  blue575: $blue575;
  blue550: $blue550;
  blue500: $blue500;
  blue600: $blue600;
  blue800: $blue800;

  light_blue200: $lightblue200;
  light_blue250: $lightblue250;
  light_blue300: $lightblue300;
  light_blue350: $lightblue350;
  light_blue400: $lightblue400;
  light_blue500: $lightblue500;

  yellow100: $yellow100;
  yellow200: $yellow200;
  yellow300: $yellow300;
  yellow400: $yellow400;

  grey_blue800: $grey_blue800;
  grey100: $grey100;
  grey200: $grey200;
  grey450: $grey450;
  grey500: $grey500;
  grey600: $grey600;
  grey650: $grey650;
  grey700: $grey700;
  grey750: $grey750;
  grey775: $grey775;
  grey800: $grey800;
  grey850: $grey850;
  grey900: $grey900;

  purple200: $purple200;
  purple400: $purple400;
  
  error: $error;

  warning_dark: $warning_dark;

  drawer: $drawer;
  tableHeader: $tableHeader;
  header_blue: $header_blue;
  footer_background: $footer_background;
  login_background: $login_background;
}