.create-customer-wrapper {
  padding-right: 8px;
  padding-top: 20px;
  #tokenizer-card,
  #tokenizer-ach {
    max-width: 60%;
    margin-bottom: 40px;
  }
  .contact-container {
    margin: 40px 0px 30px 0px;
    display: flex;
    justify-content: space-between;
    > div {
      width: 49%;
    }
  }
}

.grey__text {
  color: #00000099;
}

.dark-theme {
  .grey__text {
    color: white;
  }
}

.switch-style {
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
}

.back-arrow {
  display: flex;
  color: #1C7BC0;
  text-decoration: none;
  cursor: pointer;
  margin-left: -10px;
}

.advanced-options-wrapper {
  padding-top: 15px;
  > * {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 800px) {
  .create-customer-wrapper {
    .contact-container {
      flex-direction: column;
      > div {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
  .create-submit-button {
    button {
      width: 100%;
      margin-right: 10px;
      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
}
