@use '../../../assets/v2/variables' as *;

.gateway-selector-wrapper {
  padding: 25px;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .auto-select-dropdown {
      margin-bottom: 20px;
    }
  }

  h2.MuiTypography-root {
    padding: 0px;
    margin-top: 10px;
    padding-bottom: 20px;
    font-size: 28px;
  }
}
.large-modal-container {
  .MuiDialog-paper {
    max-width: 900px;
    max-height: calc(100% - 55px);
  }
  .tos-modal-wrapper {
    padding: 30px;
    .logo-header {
      display: flex;
      align-items: center;
      padding: 5px 0px 10px 5px;
      max-width: 110px;
      max-height: 60px;
    }
    h2 {
      width: 100%;
      font-size: 28px;
      padding: 0px;
      padding-bottom: spacer(4);
    }
    .text-section {
      background: #d9d9d926;
      padding: 30px;
    }
    .dark-theme {
      .text-section {
        background: white;
      }
    }
    .MuiPaper-root {
      max-height: 300px;
      overflow-y: scroll;
      box-shadow: none;
      background: initial;
      > div {
        margin: 0px;
      }
      .MuiTypography-root {
        width: 100%;
      }
    }
    .MuiDialogActions-root {
      justify-content: flex-start;
      padding-top: 30px;
      .loading-indicator-buttons {
        display: flex;
        align-items: center;
      }
    }
    .MuiButton-contained {
      width: 200px;
    }
  }
}

@media screen and (max-width: 575px) {
  .large-modal-container .tos-modal-wrapper .MuiDialogActions-root {
    flex-direction: column;
  }
  .fab-btn {
    display: none;
  }
  .loading-btn {
    margin: 0px;
    margin-bottom: 10px;
  }

  .gateway-selector-wrapper {
    h2.MuiTypography-root,
    .MuiFormControl-root,
    .MuiButton-root,
    .MuiAutocomplete-root {
      width: 200px;
    }
  }
  .gateway-selector-wrapper h2.MuiTypography-root {
    font-size: 20px;
  }
}
